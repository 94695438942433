import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import TraderDetails from "../Monthly";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const BankUpiApproved = () => {
    const [activeScreen, setActiveScreen] = useState("userdetail");
    const [userId, setUserId] = useState("");
    const [exportData, setExportData] = useState([]);
    const [allData, setallData] = useState([]);
    const [traderData, settraderData] = useState();

    const linkFollow = (row) => {
        return (
            <div>
                <button className="btn btn-dark btn-sm   me-2" onClick={() => { settraderData(row); setUserId(row?._id); setActiveScreen("detail") }} >View</button>
                {row?.status === 'Active' ?
                    <button className="btn btn-success btn-sm  me-2" onClick={() => { handleStatus(row?.id, 'Inactive') }} >Active</button>
                    : <button className="btn btn-danger btn-sm  me-2" onClick={() => { handleStatus(row?.id, 'Active') }}  >Inactive</button>}
            </div>
        );
    };

    const columns = [
        { name: "User ID", selector: row => row.userId, width: "185px" },
        { name: "Beneficiary Name", sort: true, wrap: true, selector: row => row?.beneficaryName, },
        { name: "Account Number", sort: true, wrap: true, selector: row => row.accountNumber, width: "185px" },
        { name: "IFSC", sort: true, wrap: true, selector: row => row.ifscCode, },
        { name: "Phone Number", sort: true, selector: row => row.mobileNumber, },
        { name: "Registration Date", sort: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY") },
        { name: "Action", selector: linkFollow, },

    ];
    function searchObjects(e) {
        const keysToSearch = ["firstName", "lastName", "emailId", "mobileNumber", "_id"];
        const userInput = e.target.value;
        const searchTerm = userInput?.toLowerCase();
        const matchingObjects = allData.filter(obj => {
            return keysToSearch.some(key => obj[key]?.toString()?.toLowerCase()?.includes(searchTerm));
        });
        setExportData(matchingObjects);
    }

    useEffect(() => {
        BankApproved();
    }, []);

    const handleStatus = async (_id, status) => {
        await AuthService.updateStatus(_id, status).then(
            async (result) => {
                if (result.success) {
                    try {
                        BankApproved();
                        alertSuccessMessage(result.message);
                    } catch (error) {
                        alertErrorMessage(error);
                    }
                } else {
                    alertErrorMessage(result.message);
                }
            }
        );
    };

    const BankApproved = async () => {
        LoaderHelper.loaderStatus(true);
        await AuthService.BankApproved().then(async (result) => {
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                try {
                    alertSuccessMessage(result.message);
                    setallData(result?.banks);
                    setExportData(result?.banks);
                } catch (error) {
                    alertErrorMessage(error);
                }
            } else {
                LoaderHelper.loaderStatus(false);
                alertErrorMessage("No Data Available");

            }
        });
    };

    const [fromDate, setFromDate] = useState('');
    const [toDate, setToDate] = useState('');

    const filterDate = () => {
        const filteredData = allData.filter((item) => {
            const createdAtDate = new Date(item.createdAt);
            return (
                (!fromDate || createdAtDate >= new Date(fromDate)) &&
                (!toDate || createdAtDate <= new Date(toDate))
            );
        });
        setExportData(filteredData?.reverse())
    }

    return activeScreen === "userdetail" ? (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon">
                                            <i className="fa fa-wave-square"></i>
                                        </div>
                                        Request/Pending Bank/UPI List
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-6">
                        <div className="card-header d-flex justify-content-between ps-4">
                            <div className="col-5">
                                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" onChange={searchObjects} />
                            </div>
                            <div className="filter_bar">
                                <form className="row">
                                    <div className="mb-3 col ">
                                        <input type="date" className="form-control form-control-solid" data-provide="datepicker"
                                            id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                                    </div>
                                    <div className="mb-3 col ">
                                        <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={toDate}
                                            onChange={(e) => { setToDate(e.target.value); }} />
                                    </div>
                                    <div className="mb-3 col ">
                                        <div className="row">
                                            <div className="col">
                                                <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                                                    Search
                                                </button>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className="card-body">
                            <div className="table-responsive" width="100%">
                                <DataTableBase columns={columns} data={exportData} />
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    ) : (
        <TraderDetails userId={userId} traderData={traderData} />
    );
};

export default BankUpiApproved;
