import React, { useEffect, useState } from "react";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";
import AuthService from "../../../api/services/AuthService";
import DataTableBase from "../../../customComponent/DataTable";
import moment from "moment";

const Transaction = () => {
  const [type, setType] = useState("all");
  const [allData, setAllData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  useEffect(() => {
    getTransaction();
  }, []);

  const getTransaction = async () => {
    try {
      LoaderHelper.loaderStatus(true);
      const result = await AuthService.allTransactions();
      LoaderHelper.loaderStatus(false);
      if (result?.success) {
        let fillteredData = result?.data
          ?.reverse()
          ?.map((item, index) => ({ ...item, index: index + 1 }));
        setAllData(fillteredData);
        setExportData(fillteredData);
      } else {
        alertErrorMessage(result?.message);
      }
    } catch (error) {
      LoaderHelper.loaderStatus(false);
      alertErrorMessage(error?.message);
    }
  };

  useEffect(() => {
    filterData();
  }, [type, fromDate, toDate]);

  const filterData = () => {
    const from = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : null;
    const to = toDate ? new Date(toDate).setHours(23, 59, 59, 999) : null;

    // Filter based on transaction type
    const typeFilteredData = allData.filter((item) => {
      return type === "all" || item?.transactionType === type;
    });

    const dateFilteredData = typeFilteredData.filter((item) => {
      const createdAtDate = new Date(item.createdAt).getTime();
      return (
        (from === null || createdAtDate >= from) &&
        (to === null || createdAtDate <= to)
      );
    });

    setExportData(dateFilteredData);
  };

  function searchObjects(e) {
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData?.filter((obj) => {
      const combinedFields = [
        obj?.paymentInstrument?.accountHolderName,
        obj?.userId,
        obj?.currentTotalBalance,
        obj?.amount,
        obj?.previousTotalBalance,
        obj?.transactionId,
        obj?.paymentInstrument?.accountType,
        obj?.transactionType,
      ]
        .map((field) => field?.toString()?.toLowerCase())
        .join("");
      return combinedFields.includes(searchTerm);
    });
    setExportData(matchingObjects);
  }


  const columns = [
    { name: "Sr No", selector: (row) => row?.index, width: "70px" },
    { name: "Date ", sort: true, wrap: true, width: "200px", selector: (row) => moment(row?.createdAt).format("Do MMMM "), },
    { name: "User Id", sort: true, wrap: true, width: "200px", selector: (row) => row?.userId },
    { name: "Amount", sort: true, wrap: true, selector: (row) => row?.amount },
    { name: <div style={{ whiteSpace: "revert" }}>Current Total Balance</div>, width: "200px", sort: true, wrap: true, selector: (row) => row?.currentTotalBalance, },
    { name: "Transaction Id", width: "200px", sort: true, wrap: true, selector: (row) => row?.transactionId },
    { name: "Transaction Type", sort: true, wrap: true, selector: (row) => row?.transactionType },
    { name: "Prize", sort: true, wrap: true, selector: (row) => row?.prize },
    { name: "Commission", sort: true, wrap: true, selector: (row) => row?.comission },
    { name: "Status", sort: true, wrap: true, selector: (row) => row?.status },
  ];

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="ri-arrow-up-down-line"></i>
                      </div>
                      Transaction History
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="card mb-4">
              <div className="card-body">
                <div className="filter_bar mb-4 mb-md-5">
                  <form className="form-inline search_form">
                    <div className="row justify-content-start">
                      <div className="form-group mb-0 col-3">
                        <div className="input text">
                          <select
                            className="form-control form-control-solid"
                            value={type}
                            onChange={(e) => setType(e.target.value)}
                          >
                            <option value="all">All</option>
                            <option value="deposit">Deposit</option>
                            <option value="withdrawal">Withdrawal</option>
                            <option value="fee">Fee</option>
                            <option value="commission">Commission</option>
                            <option value="prize">Prize</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-3">
                        <div className="input text">
                          <input
                            className="form-control form-control-solid h-auto"
                            type="text"
                            placeholder="Search...."
                            onChange={searchObjects}
                          />
                        </div>
                      </div>
                      <div className="col-2">
                        <input
                          type="date"
                          className="form-control form-control-solid"
                          value={fromDate}
                          onChange={(e) => setFromDate(e.target.value)}
                        />
                      </div>
                      <div className="col-2">
                        <input
                          type="date"
                          className="form-control form-control-solid"
                          value={toDate}
                          onChange={(e) => setToDate(e.target.value)}
                        />
                      </div>
                      <div className="col-2">
                        <button
                          className="btn btn-indigo btn-block w-100"
                          type="button"
                          onClick={filterData}
                        >
                          Search
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                <DataTableBase
                  columns={columns}
                  data={exportData}
                  pagination
                  highlightOnHover
                />
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Transaction;
