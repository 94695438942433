import React, { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import AuthService from '../../../api/services/AuthService';
import LoaderHelper from '../../../customComponent/Loading/LoaderHelper';
import { alertErrorMessage } from '../../../customComponent/CustomAlertMessage';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
import * as xlsx from 'xlsx';



function UserDetails() {
    const location = useLocation();
    const [userDetails, setUserDetails] = useState([]);
    const [type, setType] = useState("all");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [wallethistory, setWallethistory] = useState([]);
    const [GameStatics, setGameStatics] = useState([]);
    const [userKyc, setUserKyc] = useState([]);
    const [bankData, setBankData] = useState([]);
    const [earningReport, setEarningReport] = useState([]);


    useEffect(() => {
        handleUserDetails();
    }, [])
    const handleUserDetails = async () => {
        try {
            LoaderHelper.loaderStatus(true);
            const result = await AuthService.getUserDetails(location?.state?.userId);
            if (result?.success) {
                LoaderHelper.loaderStatus(false);
                setUserDetails(result?.data);
                setWallethistory(result?.data?.transactions);
                setGameStatics(result?.data?.game);
                setUserKyc(result?.data?.kycs);
                setBankData(result?.data?.bankDetails);
                setEarningReport(result?.data?.earnings);
            } else {
                alertErrorMessage(result?.message);
            }
        } catch (error) {
            LoaderHelper.loaderStatus(false);
            alertErrorMessage(error?.message);
        }
    };
    const exportToExcel = () => {
        const worksheet = xlsx.utils.json_to_sheet(
            wallethistory.map(item => ({
                "Sr.No": wallethistory.indexOf(item) + 1,
                "Date & Time": moment(item.createdAt).format('Do MMMM YYYY, h:mm A'),
                "Title": item.title,
                "Previous Amount": item.previousAmount,
                "Amount": item.amount,
                "Current Amount": item.currentAmount,
                "Credited / Debited": item.creditedDebited,
                "Status": item.status,
                "Table Id": item.numericId,
                "Game Name": item.gameName,
                "Action": item.action
            }))
        );
        const workbook = xlsx.utils.book_new();
        xlsx.utils.book_append_sheet(workbook, worksheet, "WalletHistory");
        xlsx.writeFile(workbook, "WalletHistory.xlsx");
    };

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"> <i className="fa fa-user-check"></i></div>
                                        User Details
                                    </h1>
                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="m-0 nav-link active text-white" id="pills-one-tab" data-bs-toggle="pill" data-bs-target="#pills-one" type="button" role="tab" aria-controls="pills-one" aria-selected="true">User Profile</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="m-0 nav-link text-white" id="pills-two-tab" data-bs-toggle="pill" data-bs-target="#pills-two" type="button" role="tab" aria-controls="pills-two" aria-selected="false">Wallet History</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="m-0 nav-link text-white" id="pills-three-tab" data-bs-toggle="pill" data-bs-target="#pills-three" type="button" role="tab" aria-controls="pills-three" aria-selected="false">Game Statistics</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="m-0 nav-link text-white" id="pills-four-tab" data-bs-toggle="pill" data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four" aria-selected="false">Game History</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="m-0 nav-link text-white" id="pills-five-tab" data-bs-toggle="pill" data-bs-target="#pills-five" type="button" role="tab" aria-controls="pills-five" aria-selected="false">User KYC</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="m-0 nav-link text-white" id="pills-six-tab" data-bs-toggle="pill" data-bs-target="#pills-six" type="button" role="tab" aria-controls="pills-six" aria-selected="false">Bank / UPI</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="m-0 nav-link text-white" id="pills-seven-tab" data-bs-toggle="pill" data-bs-target="#pills-seven" type="button" role="tab" aria-controls="pills-seven" aria-selected="false">Earning Report</button>
                            </li>
                        </ul>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="row">
                        <div className="col-xl-12 mb-4">
                            <div className="card mb-4 mb-xl-0">
                                <div className="card-body py-5 pb-0">
                                    <div className="tab-content">
                                        {/* User Profile Tab */}
                                        <div className="tab-pane fade show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
                                            <div className="list_profile">
                                                <div>
                                                    <img className="img-account-profile rounded-circle mb-2 mb-lg-0" src="../../assets/img/dummy.png" alt="" />
                                                    <h3 className="fw-bolder  mb-0">{userDetails?.user?.username ? userDetails?.user?.username : "N/A"}</h3>
                                                </div>
                                                <button type="button" className="btn btn-primary btn-muted" data-bs-toggle="modal" data-bs-target="#add_modal">Edit</button>
                                            </div>

                                            <div className="doc_img py-5 px-4 my-4">
                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">User Name:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark">{userDetails?.user?.username ? userDetails?.user?.username : "N/A"}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Phone Number:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark">{userDetails?.user?.mobileNumber ? userDetails?.user?.mobileNumber : "N/A"}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Device Type:</label>
                                                    <div className="col-lg-7 fv-row">
                                                        <span className="fw-bolder fs-6 text-dark">{userDetails?.user?.fcmDevice ? userDetails?.user?.fcmDevice : "N/A"}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Document Name:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark text-hover-primary">{userDetails?.kycs?.name ? userDetails?.kycs?.name : "N/A"}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Document Number:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark text-hover-primary">{userDetails?.kycs?.documentNumber ? userDetails?.kycs?.documentNumber : "N/A"}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Winning:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark text-hover-primary">₹ {userDetails?.transactions?.map((transaction) => (
                                                            transaction.title === "Prize" ? transaction.amount : null))}</span>
                                                        <button type="button" className="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#winning_modal" style={{ marginLeft: "300px" }} >+ Add Winning Cash</button>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Deposit Cash:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark text-hover-primary">₹ {userDetails?.user?.depositBalance ? userDetails?.user?.depositBalance : 0}</span>
                                                        <button type="button" className="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#Deposit_modal" style={{ marginLeft: "300px" }}>+ Add Deposit Cash</button>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Bonus:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark text-hover-primary">₹ {userDetails?.user?.cashBonus ? userDetails?.user?.cashBonus : 0}</span>
                                                        <button type="button" className="btn btn-sm btn-success" data-bs-toggle="modal" data-bs-target="#Bonus_modal" style={{ marginLeft: "320px" }}>+ Add Bonus</button>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Total Cash:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark text-hover-primary">₹ {userDetails?.user?.totalBalance ? userDetails?.user?.totalBalance : 0}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Total Deposits:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark text-hover-primary">₹ {userDetails?.user?.depositBalance ? userDetails?.user?.depositBalance : 0}</span>
                                                    </div>
                                                </div>

                                                <div className="row mb-3">
                                                    <label className="col-lg-5 fw-bold text-muted">Total Withdrawals:</label>
                                                    <div className="col-lg-7">
                                                        <span className="fw-bolder fs-6 text-dark text-hover-primary">₹ {userDetails?.user?.withdrawl ? userDetails?.user?.withdrawl : 0}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Wallet History Tab */}
                                        <div className="tab-pane fade" id="pills-two" role="tabpanel" aria-labelledby="pills-two-tab">
                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User ID:</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.id} </span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Phone No :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.mobileNumber}</span>
                                                </div>
                                            </div>

                                            <div className='d-flex align-items-center  justify-content-between my-3 '>
                                                <div className="dropdown">
                                                    <button className="btn btn-dark dropdown-toggle m-0" id="dropdownFadeInUp" type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                                        aria-expanded="false">
                                                        <i className="fa fa-download me-3"></i> Export
                                                    </button>
                                                    <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                                                        <CSVLink data={wallethistory} className="dropdown-item">
                                                            Export as CSV
                                                        </CSVLink>
                                                        <button onClick={exportToExcel} className="dropdown-item">Export to Excel</button>
                                                    </div>
                                                </div>

                                                <div>
                                                    <select type="text" className="form-control form-select form-control-solid w-auto m-0" name="game" id="" value={type} onChange={(e) => setType(e.target.value)}>
                                                        <option value="Alldays">All Days</option>
                                                        <option value="Today">Today</option>
                                                        <option value="Yesterday">Yesterday</option>
                                                        <option value="Last 7 Days">Last 7 Days</option>
                                                        <option value="Last 30 Days">Last 30 Days</option>
                                                        <option value="Custom">Custom Range</option>
                                                    </select>
                                                    {type === 'Custom' && (
                                                        <div className="mt-3">
                                                            <label htmlFor="fromDate">From:</label>
                                                            <input type="date" className="form-control form-control-solid" id="fromDate" name="fromDate" value={fromDate} onChange={(e) => setFromDate(e.target.value)} />
                                                            <label htmlFor="toDate" className="mt-2">To:</label>
                                                            <input type="date" className="form-control form-control-solid" id="toDate" name="toDate" value={toDate} onChange={(e) => setToDate(e.target.value)} />
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr style={{ width: '15%' }}>
                                                            <th>Sr.No.</th>
                                                            <th>Date & Time</th>
                                                            <th>Title</th>
                                                            <th>Previous Amount</th>
                                                            <th>Amount</th>
                                                            <th>Current Amount</th>
                                                            <th>Credited / Debited</th>
                                                            <th>Status</th>
                                                            <th>Table Id</th>
                                                            <th>Game Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(wallethistory) && wallethistory.length > 0 ? (
                                                            wallethistory.map((item, index) => (
                                                                <tr key={index} style={{ whiteSpace: 'nowrap' }}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{moment(item.createdAt).format('Do MMMM YYYY')}</td>
                                                                    <td style={{ overflowWrap: 'break-word' }}>{item.title}</td>
                                                                    <td>{item.previousAmount}</td>
                                                                    <td>{item.amount}</td>
                                                                    <td>{item.currentAmount}</td>
                                                                    <td>{item.creditedDebited}</td>
                                                                    <td>{item.status}</td>
                                                                    <td>{item.numericId}</td>
                                                                    <td style={{ overflowWrap: 'break-word' }}>{item.gameName}</td>
                                                                    <td>{item.action}</td>
                                                                </tr>
                                                            ))) : (
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center' }}>No data found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                        {/* Game Statistics Tab */}
                                        <div className="tab-pane fade" id="pills-three" role="tabpanel" aria-labelledby="pills-three-tab">
                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User ID:</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.id} </span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Phone No :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.mobileNumber}</span>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr style={{ width: '15%' }}>
                                                            <th>Sr.No.</th>
                                                            <th>Game Name</th>
                                                            <th>BATTLE  (One Vs One)</th>
                                                            <th>CONTEST (One Vs Many)</th>
                                                            <th>Total Loss</th>
                                                            <th>Total Win</th>
                                                            <th>Total Tie</th>
                                                            <th>Total Played</th>

                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(GameStatics) && GameStatics.length > 0 ? (
                                                            GameStatics.map((item, index) => (
                                                                <tr key={index} style={{ whiteSpace: 'nowrap' }}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.gameName}</td>
                                                                    <td style={{ overflowWrap: 'break-word' }}>{item.battle}</td>
                                                                    <td>{item.Contest}</td>
                                                                    <td>{item.totalLoss}</td>
                                                                    <td>{item.totalWin}</td>
                                                                    <td>{item.totalTie}</td>
                                                                    <td>{item.totalPlayed}</td>
                                                                </tr>
                                                            ))) : (
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center' }}>No data found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* Game History Tab */}
                                        <div className="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab">
                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User ID:</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.id} </span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Phone No :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.mobileNumber}</span>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr style={{ width: '15%' }}>
                                                            <th>Sr.No.</th>
                                                            <th>Game ID</th>
                                                            <th>Game Name</th>
                                                            <th>Game History</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(GameStatics) && GameStatics.length > 0 ? (
                                                            GameStatics.map((item, index) => (
                                                                <tr key={index} style={{ whiteSpace: 'nowrap' }}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.gameid}</td>
                                                                    <td >{item.gameName}</td>
                                                                    <td>{item.gameHistory}</td>
                                                                </tr>
                                                            ))) : (
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center' }}>No data found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* User KYC Tab */}
                                        <div className="tab-pane fade" id="pills-five" role="tabpanel" aria-labelledby="pills-five-tab">
                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User ID:</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.id} </span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Phone No :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.mobileNumber}</span>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr style={{ width: '15%' }}>
                                                            <th>Sr.No.</th>
                                                            <th>Pan Card Number</th>
                                                            <th>Pan Card Photo</th>
                                                            <th>Aadhaar Card Number</th>
                                                            <th>Aadhaar Card Photo</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(userKyc) && userKyc.length > 0 ? (
                                                            userKyc.map((item, index) => (
                                                                <tr key={index} style={{ whiteSpace: 'nowrap' }}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.panNumber}</td>
                                                                    <td >{item.panImage}</td>
                                                                    <td>{item.adharNumber}</td>
                                                                    <td>{item.adharimage}</td>
                                                                    <td>{item.status}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center' }}>No data found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* Bank / UPI Tab */}
                                        <div className="tab-pane fade" id="pills-six" role="tabpanel" aria-labelledby="pills-six-tab">
                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User ID:</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.id} </span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Phone No :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.mobileNumber}</span>
                                                </div>
                                                <div className="row mb-3 d-flex justify-content-end">
                                                    <select type="text" className="form-control form-select form-control-solid w-auto m-0" name="game" id="" value={type} onChange={(e) => setType(e.target.value)}>
                                                        <option value="alldata">All</option>
                                                        <option value="upiList">UPI List</option>
                                                        <option value="bankList">Bank List</option>
                                                    </select>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr>
                                                            <th>Sr.No.</th>
                                                            <th>Bank Account Name</th>
                                                            <th>Bank Account Number</th>
                                                            <th>UPI Id</th>
                                                            <th>UPI Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(bankData) && bankData.length > 0 ? (
                                                            bankData.map((item, index) => (
                                                                <tr key={index} style={{ whiteSpace: 'nowrap' }}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.panNumber}</td>
                                                                    <td >{item.panImage}</td>
                                                                    <td>{item.adharNumber}</td>
                                                                    <td>{item.adharimage}</td>
                                                                    <td>{item.status}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center' }}>No data found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                        {/* Earning Report Tab */}
                                        <div className="tab-pane fade" id="pills-seven" role="tabpanel" aria-labelledby="pills-seven-tab">
                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User ID:</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.id} </span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">User Name :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.username}</span>
                                                </div>
                                            </div>

                                            <div className="row mb-3">
                                                <label className="col-lg-5 fw-bold text-muted">Phone No :</label>
                                                <div className="col-lg-7">
                                                    <span className="fw-bold fs-6 text-dark text-hover-primary">{userDetails?.user?.mobileNumber}</span>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <table className="table table-sm">
                                                    <thead>
                                                        <tr style={{ width: '15%' }}>
                                                            <th>Sr.No.</th>
                                                            <th>Game ID</th>
                                                            <th>Game Name</th>
                                                            <th>Net Credited Amount</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.isArray(earningReport) && earningReport.length > 0 ? (
                                                            earningReport.map((item, index) => (
                                                                <tr key={index} style={{ whiteSpace: 'nowrap' }}>
                                                                    <td>{index + 1}</td>
                                                                    <td>{item.gameid}</td>
                                                                    <td >{item.gameName}</td>
                                                                    <td>{item.notCredit}</td>
                                                                </tr>
                                                            ))
                                                        ) : (
                                                            <tr>
                                                                <td colSpan="11" style={{ textAlign: 'center' }}>No data found</td>
                                                            </tr>
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main >
            {/* user details add_modal */}
            < div className="modal fade" id="add_modal" tabindex="-1" ariaLabelledby="add_modalLabel" ariaHidden="true" >
                <div className="modal-dialog modal-lg modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="add_modalLabel">
                                Edit User Profile                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-4 gx-md-5">
                                <div className="col-md-4">
                                    <label>
                                        Full Name <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" className="form-control form-control-solid" name="wonCoin" placeholder=" Enter Full Name" />
                                </div>

                                <div className="col-md-4">
                                    <label>
                                        Phone Number <span className="text-danger">* </span>
                                    </label>
                                    <input type="number" className="form-control form-control-solid" name="totalBet" placeholder="Enter Phone Number" />
                                </div>

                                <div className="col-md-4">
                                    <label>
                                        Country <span className="text-danger">* </span>
                                    </label>
                                    <select className="form-control form-control-solid" name="country" required>
                                        <option value="">Select Country</option>
                                        <option value="Afghanistan">Afghanistan</option>
                                        <option value="Albania">Albania</option>
                                        <option value="Algeria">Algeria</option>
                                        <option value="Andorra">Andorra</option>
                                        <option value="Angola">Angola</option>
                                        <option value="Argentina">Argentina</option>
                                        <option value="Armenia">Armenia</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Azerbaijan">Azerbaijan</option>
                                        <option value="Bahamas">Bahamas</option>
                                        <option value="Bahrain">Bahrain</option>
                                        <option value="Bangladesh">Bangladesh</option>
                                        <option value="Barbados">Barbados</option>
                                        <option value="Belarus">Belarus</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Belize">Belize</option>
                                        <option value="Benin">Benin</option>
                                        <option value="Bhutan">Bhutan</option>
                                        <option value="Bolivia">Bolivia</option>
                                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                                        <option value="Botswana">Botswana</option>
                                        <option value="Brazil">Brazil</option>
                                        <option value="Brunei">Brunei</option>
                                        <option value="Bulgaria">Bulgaria</option>
                                        <option value="Burkina Faso">Burkina Faso</option>
                                        <option value="Burundi">Burundi</option>
                                        <option value="Cambodia">Cambodia</option>
                                        <option value="Cameroon">Cameroon</option>
                                        <option value="Canada">Canada</option>
                                        <option value="Cape Verde">Cape Verde</option>
                                        <option value="Central African Republic">Central African Republic</option>
                                        <option value="Chad">Chad</option>
                                        <option value="Chile">Chile</option>
                                        <option value="China">China</option>
                                        <option value="Colombia">Colombia</option>
                                        <option value="Comoros">Comoros</option>
                                        <option value="Congo">Congo</option>
                                        <option value="Costa Rica">Costa Rica</option>
                                        <option value="Croatia">Croatia</option>
                                        <option value="Cuba">Cuba</option>
                                        <option value="Cyprus">Cyprus</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Djibouti">Djibouti</option>
                                        <option value="Dominica">Dominica</option>
                                        <option value="Dominican Republic">Dominican Republic</option>
                                        <option value="East Timor">East Timor</option>
                                        <option value="Ecuador">Ecuador</option>
                                        <option value="Egypt">Egypt</option>
                                        <option value="El Salvador">El Salvador</option>
                                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                                        <option value="Eritrea">Eritrea</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="Eswatini">Eswatini</option>
                                        <option value="Ethiopia">Ethiopia</option>
                                        <option value="Fiji">Fiji</option>
                                        <option value="Finland">Finland</option>
                                        <option value="France">France</option>
                                        <option value="Gabon">Gabon</option>
                                        <option value="Gambia">Gambia</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Ghana">Ghana</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Grenada">Grenada</option>
                                        <option value="Guatemala">Guatemala</option>
                                        <option value="Guinea">Guinea</option>
                                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                                        <option value="Guyana">Guyana</option>
                                        <option value="Haiti">Haiti</option>
                                        <option value="Honduras">Honduras</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Iceland">Iceland</option>
                                        <option value="India">India</option>
                                        <option value="Indonesia">Indonesia</option>
                                        <option value="Iran">Iran</option>
                                        <option value="Iraq">Iraq</option>
                                        <option value="Ireland">Ireland</option>
                                        <option value="Israel">Israel</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Ivory Coast">Ivory Coast</option>
                                        <option value="Jamaica">Jamaica</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Jordan">Jordan</option>
                                        <option value="Kazakhstan">Kazakhstan</option>
                                        <option value="Kenya">Kenya</option>
                                        <option value="Kiribati">Kiribati</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                                        <option value="Laos">Laos</option>
                                        <option value="Latvia">Latvia</option>
                                        <option value="Lebanon">Lebanon</option>
                                        <option value="Lesotho">Lesotho</option>
                                        <option value="Liberia">Liberia</option>
                                        <option value="Libya">Libya</option>
                                        <option value="Liechtenstein">Liechtenstein</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Luxembourg">Luxembourg</option>
                                        <option value="Madagascar">Madagascar</option>
                                        <option value="Malawi">Malawi</option>
                                        <option value="Malaysia">Malaysia</option>
                                        <option value="Maldives">Maldives</option>
                                        <option value="Mali">Mali</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Marshall Islands">Marshall Islands</option>
                                        <option value="Mauritania">Mauritania</option>
                                        <option value="Mauritius">Mauritius</option>
                                        <option value="Mexico">Mexico</option>
                                        <option value="Micronesia">Micronesia</option>
                                        <option value="Moldova">Moldova</option>
                                        <option value="Monaco">Monaco</option>
                                        <option value="Mongolia">Mongolia</option>
                                        <option value="Montenegro">Montenegro</option>
                                        <option value="Morocco">Morocco</option>
                                        <option value="Mozambique">Mozambique</option>
                                        <option value="Myanmar">Myanmar</option>
                                        <option value="Namibia">Namibia</option>
                                        <option value="Nauru">Nauru</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Nicaragua">Nicaragua</option>
                                        <option value="Niger">Niger</option>
                                        <option value="Nigeria">Nigeria</option>
                                        <option value="North Korea">North Korea</option>
                                        <option value="North Macedonia">North Macedonia</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Oman">Oman</option>
                                        <option value="Pakistan">Pakistan</option>
                                        <option value="Palau">Palau</option>
                                        <option value="Panama">Panama</option>
                                        <option value="Papua New Guinea">Papua New Guinea</option>
                                        <option value="Paraguay">Paraguay</option>
                                        <option value="Peru">Peru</option>
                                        <option value="Philippines">Philippines</option>
                                        <option value="Poland">Poland</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Qatar">Qatar</option>
                                        <option value="Romania">Romania</option>
                                        <option value="Russia">Russia</option>
                                        <option value="Rwanda">Rwanda</option>
                                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                                        <option value="Saint Lucia">Saint Lucia</option>
                                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                                        <option value="Samoa">Samoa</option>
                                        <option value="San Marino">San Marino</option>
                                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                                        <option value="Saudi Arabia">Saudi Arabia</option>
                                        <option value="Senegal">Senegal</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Seychelles">Seychelles</option>
                                        <option value="Sierra Leone">Sierra Leone</option>
                                        <option value="Singapore">Singapore</option>
                                        <option value="Slovakia">Slovakia</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Solomon Islands">Solomon Islands</option>
                                        <option value="Somalia">Somalia</option>
                                        <option value="South Africa">South Africa</option>
                                        <option value="South Korea">South Korea</option>
                                        <option value="South Sudan">South Sudan</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Sri Lanka">Sri Lanka</option>
                                        <option value="Sudan">Sudan</option>
                                        <option value="Suriname">Suriname</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Syria">Syria</option>
                                        <option value="Taiwan">Taiwan</option>
                                        <option value="Tajikistan">Tajikistan</option>
                                        <option value="Tanzania">Tanzania</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Togo">Togo</option>
                                        <option value="Tonga">Tonga</option>
                                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                                        <option value="Tunisia">Tunisia</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Turkmenistan">Turkmenistan</option>
                                        <option value="Tuvalu">Tuvalu</option>
                                        <option value="Uganda">Uganda</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                        <option value="Uruguay">Uruguay</option>
                                        <option value="Uzbekistan">Uzbekistan</option>
                                        <option value="Vanuatu">Vanuatu</option>
                                        <option value="Vatican City">Vatican City</option>
                                        <option value="Venezuela">Venezuela</option>
                                        <option value="Vietnam">Vietnam</option>
                                        <option value="Yemen">Yemen</option>
                                        <option value="Zambia">Zambia</option>
                                        <option value="Zimbabwe">Zimbabwe</option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary px-5" data-bs-dismiss="modal" >
                                Update
                            </button>
                            <button type="button" className="btn btn-dark  px-5" data-bs-dismiss="modal">
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            {/* Add winning cash modal */}
            < div className="modal fade" id="winning_modal" tabindex="-1" ariaLabelledby="add_modalLabel" ariaHidden="true" >
                <div className="modal-dialog modal-l modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="add_modalLabel">
                                Add Winning Cash
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row g-4 gx-md-5">
                                <div className="col-md-12">
                                    <label>
                                        Winning Cash  <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" className="form-control form-control-solid" name="winningCash" />
                                </div>
                            </div>

                            <div className="common_checkbox_details mt_more_margin"><label>Is Deduct TDS  ? <span className="validation-star">*</span></label>
                                <div className="game_mode_btn">
                                    <div className="game_mode_btn_option yes_radio_btn">
                                        <input type="radio" name="isDeductTds" />
                                        <label>Yes</label></div>
                                    <div className="game_mode_btn_option no_radio_btn">
                                        <input type="radio" name="isDeductTds" /><label>No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark  px-5" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary px-5" data-bs-dismiss="modal" >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            {/* Add Deposit Cash modal */}
            < div className="modal fade" id="Deposit_modal" tabindex="-1" ariaLabelledby="add_modalLabel" ariaHidden="true" >
                <div className="modal-dialog modal-l modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="add_modalLabel">
                                Add Deposit Cash
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-4 gx-md-5">
                                <div className="col-md-12">
                                    <label>
                                        Deposit Cash  <span className="text-danger">* </span>
                                    </label>
                                    <input type="text" className="form-control form-control-solid" name="winningCash" />
                                </div>
                            </div>
                        </div>

                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark  px-5" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary px-5" data-bs-dismiss="modal" >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div >
            {/* Add Bonus_modal  */}
            < div className="modal fade" id="Bonus_modal" tabindex="-1" ariaLabelledby="add_modalLabel" ariaHidden="true" >
                <div className="modal-dialog modal-l modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="add_modalLabel">
                                Add Bonus
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>

                        <div className="modal-body">
                            <div className="row g-4 gx-md-5">
                                <div className="col-md-12">
                                    <label>
                                        Bonus<span className="text-danger">* </span>
                                    </label>
                                    <input type="text" className="form-control form-control-solid" name="winningCash" />
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-dark  px-5" data-bs-dismiss="modal">
                                Cancel
                            </button>
                            <button type="button" className="btn btn-primary px-5" data-bs-dismiss="modal" >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserDetails;
