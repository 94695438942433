import React, { useState } from "react";
import { alertWarningMessage } from "../../../customComponent/CustomAlertMessage";

const NewCreate = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [gender, setGender] = useState('male');
    const [passwords, setPassword] = useState('');
    const [dob, setDob] = useState('');
    const [signId, setSignId] = useState('')
    const [confirmPassword, setConfirmPassword] = useState("")
    const [number, setNumber] = useState('');
    const [check, setcheck] = useState('');
    const [index, setindex] = useState();
    const [totalPerc, setTotalPerc] = useState('');


    const handleInputChange = (event) => {
        switch (event.target.name) {
            case "firstName":
                setFirstName(event.target.value);
                break;
            case "lastName":
                setLastName(event.target.value);
                break;
            case "gender":
                setGender(event.target.value);
                break;
            case "number":
                setNumber(event.target.value);
                break;
            case "emailorphone":
                setSignId(event.target.value);
                break;
            case "password":
                setPassword(event.target.value);
                break;
            case "confirmPassword":
                setConfirmPassword(event.target.value)
                break;
            case "dob":
                setDob(event.target.value);
                break;
            default:
        }
    }


    const addField = (index) => {
        let length = check.length - 1
        if (!check[length]['EndRank'] && !check[length]['PercentageEach']) {
            alertWarningMessage('Please fill the row first')

        } else if (totalPerc >= 100) {
            alertWarningMessage('You can not add one more row')
        } else {
            setcheck((prev) => [...prev, { 'StartRank': +check[length]['EndRank'] + 1, 'EndRank': '', 'PercentageEach': '', 'Price': '', 'TotalPercentage': '', 'TotalPrice': '' }])
        }
    }

    return (
        <div id="layoutSidenav_content">
            <main>
                <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
                    <div className="container-xl px-4">
                        <div className="page-header-content pt-4">
                            <div className="row align-items-center justify-content-between">
                                <div className="col-auto mt-4">
                                    <h1 className="page-header-title">
                                        <div className="page-header-icon"><i className="far fa-user"></i></div>
                                        Tournament Create
                                    </h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                <div className="container-xl px-4 mt-n10">
                    <div className="card mb-4">
                        <div className="card-header">
                            Tournament Create
                            <button className="btn btn-danger btn-block btn-md px-4 w-40 mt-1">Tournament List</button>
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="row gx-3 mb-3">
                                    <div className="col-md-3">
                                        <label className="small mb-1" for="inputFirstName">Start <em>*</em></label>
                                        <input type="text" className="form-control  form-control-solid" id="inputFirstName" placeholder="Enter your first name" name="firstName" value={firstName} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1" for="inputLastNames">End <em>*</em></label>
                                        <input className="form-control form-control-solid" id="inputLastNames" type="text" placeholder="Enter your last name" name="lastName" value={lastName} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1" for="inputLastNames">Total Entry <em>*</em> </label>
                                        <input className="form-control form-control-solid" id="inputLastNames" type="text" placeholder="Enter your last name" name="lastName" value={lastName} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1" for="inputLastNames">Max Entry Each Player <em>*</em> </label>
                                        <input className="form-control form-control-solid" id="inputLastNames" type="text" placeholder="Enter your last name" name="lastName" value={lastName} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1" for="inputLastNames">Entry Fee <em>*</em> </label>
                                        <input className="form-control form-control-solid" id="inputLastNames" type="text" placeholder="Enter your last name" name="lastName" value={lastName} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1" for="inputLastNames">Total Prize Pool <em>*</em> </label>
                                        <input className="form-control form-control-solid" id="inputLastNames" type="text" placeholder="Enter your last name" name="lastName" value={lastName} onChange={handleInputChange} />
                                    </div>
                                    <div className="col-md-3">
                                        <label className="small mb-1" for="inputLastNames">Free Percent <em>*</em> </label>
                                        <input className="form-control form-control-solid" id="inputLastNames" type="text" placeholder="Enter your last name" name="lastName" value={lastName} onChange={handleInputChange} />
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckDefault">
                                            Status
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckDefault">
                                            Repeated
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                    <div className="form-check">
                                        <label className="form-check-label" for="flexCheckDefault">
                                            Show on Top
                                        </label>
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                                    </div>
                                </div>
                                <div className=' d-flex justify-content' >
                                    <button className="btn btn-success btn-block btn-md px-4 w-40 mt-1" onClick={() => { addField(index) }}>Add row</button>
                                </div>
                            </form>

                            <div className="d-flex justify-content-end">
                                <div>
                                    <button className="btn btn-indigo btn-block btn-md px-4 w-40 mt-1" onClick={() => { addField(index) }}>Save</button>
                                </div>
                                <div >
                                    <button className="btn btn-danger btn-block btn-md px-4 w-40 mt-1" onClick={() => { addField(index) }}>Save and Live</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default NewCreate;