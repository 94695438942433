import moment from "moment";
import React, { useEffect, useState } from "react";
import AuthService from "../../../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../../customComponent/Loading/LoaderHelper";
import { CSVLink } from "react-csv";
import DataTableBase from "../../../../customComponent/DataTable";
import { ApiConfig } from "../../../../api/apiConfig/ApiConfig";

const PendigWithdrawal = () => {
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);

  const linkFollow = (row) => {
    return (
      <div>
        {row?.status === "Pending" ? (
          <>
            <button
              className="btn btn-success btn-sm me-2"
              onClick={() => {
                handlePendingStatus(row?._id, row?.userId);
              }}
            >
              Approve
            </button>
            <button
              className="btn btn-danger btn-sm me-2"
              onClick={() => {
                handlePendingStatus(row?._id, row?.userId);
              }}
            >
              Reject
            </button>
          </>
        ) : (
          <button className="btn btn-secondary btn-sm me-2" disabled>
            {row?.status}
          </button>
        )}
      </div>
    );
  };
  function imageFormatter(row) {
    return (
      <img
        crossOrigin="anonymous"
        style={{ width: "40%", height: "auto" }}
        className="table-img"
        src={ApiConfig?.appUrl + row?.screenshot}
        alt="Selfie"
      />
    );
  }

  const columns = [
    {
      name: "S.No",
      sort: true,
      selector: (row, index) => row?.index,
      width: "80px",
    },
    { name: "User ID", selector: (row) => row.userId, wrap: true, grow: 0.1 },
    {
      name: "Transaction Into",
      sort: true,
      wrap: true,
      selector: (row) => row?.transactionInto,
      grow: 0.1,
    },
    {
      name: "Title",
      sort: true,
      wrap: true,
      selector: (row) => row.title,
      grow: 0.1,
    },
    {
      name: "Screen Shot",
      sort: true,
      wrap: true,
      selector: imageFormatter,
      grow: 0.1,
    },
    {
      name: <div style={{ whiteSpace: "revert" }}>Description</div>,
      sort: true,
      selector: (row) => row.description,
      grow: 0.1,
      wrap: true,
    },
    {
      name: <div style={{ whiteSpace: "revert" }}>Amount</div>,
      sort: true,
      selector: (row) => row.amount,
      grow: 0.1,
    },
    {
      name: <div style={{ whiteSpace: "revert" }}>Transation Type</div>,
      sort: true,
      selector: (row) => row.transactionType,
      grow: 0.1,
    },
    {
      name: <div style={{ whiteSpace: "revert" }}>Registration Date</div>,
      sort: true,
      selector: (row) => moment(row?.createdAt).format("Do MMMM YYYY"),
      grow: 0.1,
      wrap: true,
    },
    { name: "Action", selector: linkFollow, wrap: true, grow: 0.1 },
  ];

  function searchObjects(e) {
    const keysToSearch = [
      "transactionInto",
      "userId",
      "title",
      "previousWinningBalance",
      "previousTotalBalance",
      "currentWinningBalance",
      "currentTotalBalance",
      "transactionType",
    ];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter((obj) => {
      return keysToSearch.some((key) =>
        obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)
      );
    });
    setExportData(matchingObjects);
  }

  useEffect(() => {
    handlePendingWithdrawal();
  }, []);

  const handlePendingStatus = async (userId, _id) => {
    await AuthService.pendingWithraStatus(userId, _id).then(async (result) => {
      if (result.success) {
        try {
          handlePendingWithdrawal();
          alertSuccessMessage(result.message);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const handlePendingWithdrawal = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getPendingwithdrawal().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          let fillteredData = result?.trans
            ?.reverse()
            ?.map((item, index) => ({ ...item, index: index + 1 }));
          setExportData(fillteredData.reverse());
          setallData(fillteredData);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");
      }
    });
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const filterDate = () => {
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt);
      return (
        (!fromDate || createdAtDate >= new Date(fromDate)) &&
        (!toDate || createdAtDate <= new Date(toDate))
      );
    });
    setExportData(filteredData?.reverse());
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Request/Pending Withdrawal List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-6 ">
            <div className="card-header d-flex justify-content-between p-3 ps-4 ">
              <p className="mb-0">
                <span style={{ color: "red" }}> TOTAL RECORD </span>{" "}
                <b className="text-green ms-1 "> {allData.length}</b>
              </p>
            </div>
            <div className="card-header d-flex justify-content-between">
              <div className="filter_bar">
                <form className="row">
                  <div className="col-3">
                    <input
                      className="form-control form-control-solid h-auto"
                      id="inputLastName"
                      type="text"
                      placeholder="Player ID"
                      name="search"
                      onChange={searchObjects}
                    />
                  </div>
                  <div className="col-2">
                    <input
                      className="form-control form-control-solid h-auto"
                      id="inputLastName"
                      type="text"
                      placeholder="User Name / Mobile Number"
                      name="search"
                      onChange={searchObjects}
                    />
                  </div>

                  <div className="col-2">
                    <input
                      type="date"
                      className="form-control form-control-solid"
                      data-provide="datepicker"
                      id="litepickerRangePlugin"
                      name="dateFrom"
                      value={fromDate}
                      onChange={(e) => {
                        setFromDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <input
                      type="date"
                      className="form-control form-control-solid"
                      data-provide="datepicker"
                      id="litepickerRangePlugin"
                      name="dateTo"
                      value={toDate}
                      onChange={(e) => {
                        setToDate(e.target.value);
                      }}
                    />
                  </div>
                  <div className="col-2">
                    <button
                      className="btn btn-indigo btn-block w-100"
                      type="button"
                      onClick={filterDate}
                    >
                      Search
                    </button>
                  </div>
                </form>
              </div>
              <div className="dropdown">
                <button
                  className="btn btn-dark btn-l dropdown-toggle"
                  id="dropdownFadeInUp"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div
                  className="dropdown-menu animated--fade-in-up"
                  aria-labelledby="dropdownFadeInUp"
                >
                  <CSVLink data={exportData} className="dropdown-item">
                    Export as CSV
                  </CSVLink>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase
                  columns={columns}
                  data={exportData}
                  pagination={true}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default PendigWithdrawal;
