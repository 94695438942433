import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage } from "../../../customComponent/CustomAlertMessage";

const HomePage = () => {
  const userType = sessionStorage.getItem("userType");
  const myPermission = sessionStorage.getItem("permissions");
  let permissions = Array.isArray(JSON.parse(myPermission))
    ? JSON.parse(myPermission)?.map((x) => x.value)
    : [];
  const [activeScreen, setActiveScreen] = useState("");
  const [todayWithdrawal, setTodayWithdrawal] = useState("");
  const [todayBet, setTodayBet] = useState("");
  const [todayPlayers, setTodayPlayers] = useState("");
  const [todayFee, setTodayFee] = useState("");
  const [totalBet, setTotalBet] = useState("");
  const [todayCommission, setTodayCommission] = useState("");
  const [totalBalance, setTotalBalance] = useState("");
  const [todayTables, setTodayTables] = useState("");
  const [totalCommission, setTotalCommission] = useState("");
  const [totalTables, setTotalTables] = useState("");
  const [todayDeposit, setTodayDeposit] = useState("");
  const [playersPlaying, setPlayersPlaying] = useState("");

  useEffect(() => {
    handleDashboardData();
    setTotalBet();
  }, []);

  const handleDashboardData = async () => {
    await AuthService.getDashboardData().then(async (result) => {
      if (result.success) {
        try {
          setTodayCommission(result?.data?.todayCommission);
          setTodayBet(result?.data?.todayBet);
          setTodayTables(result?.data?.todayTables);
          setTotalCommission(result?.data?.totalCommission);
          setTotalBet(result?.data?.totalBet);
          setTotalTables(result?.data?.totalTables);
          setTodayDeposit(result?.data?.todayDeposit);
          setTodayFee(result?.data?.todayFee);
          setTodayWithdrawal(result?.data?.todayWithdrawal);
          setTodayPlayers(result?.data?.todayPlayers);
          setTotalBalance(result?.data?.totalBalance);
          setPlayersPlaying(result?.data?.playersPlaying);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result?.message);
        console.log(result);
      }
    });
  };

  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-th"></i>
                      </div>
                      Dashboard
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="row">
              {permissions.includes(1) || userType === "1" ? (
                <div className="col-lg-6 col-xl-4 mb-4">
                  <div className="card bg-primary text-white h-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-3">
                          <div className="text-white-75">Today Commission</div>
                          <div className="display-4 fw-bold">
                            {todayCommission ? (
                              todayCommission
                            ) : (
                              <small>0</small>
                            )}{" "}
                          </div>
                        </div>
                        <i className="feather-xl text-white-50 fa fa-user-friends"></i>
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-between small">
                      <Link
                        className="text-white stretched-link"
                        to="/dashboard/Transactions"
                        style={{ cursor: "pointer" }}
                      >
                        View All
                      </Link>
                      <div className="text-white">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              {permissions.includes(2) || userType === "1" ? (
                <>
                  <div className="col-lg-6 col-xl-4 mb-4">
                    <div className="card bg-success text-white h-100">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="me-3">
                            <div className="text-white-75">Today Bet</div>
                            <div className="display-4 fw-bold">
                              {todayBet ? todayBet : <small>0</small>}
                            </div>
                          </div>
                          <i className="feather-xl text-white-50 fa fa-user-check"></i>
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between small">
                        <Link
                          className="text-white stretched-link"
                          to="/dashboard/GameList"
                          style={{ cursor: "pointer" }}
                        >
                          View All
                        </Link>
                        <div className="text-white">
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-4 mb-4">
                    <div className="card bg-danger text-white h-100">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="me-3">
                            <div className="text-white-75 ">Today Tables</div>
                            <div className="display-4 fw-bold">
                              {todayTables ? todayTables : <small>0</small>}
                            </div>
                          </div>
                          <i className="feather-xl text-white-50 fa fa-user-slash "></i>
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between small">
                        <Link
                          className="text-white stretched-link"
                          to="/dashboard/TableList"
                          style={{ cursor: "pointer" }}
                        >
                          View All
                        </Link>
                        <div className="text-white">
                          <i className="fa fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
            </div>

            <div className="row">
              {userType === "1" ? (
                <div className="col-lg-6 col-xl-4 mb-4">
                  <div className="card bg-dark text-white h-100">
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div className="me-3">
                          <div className="text-white-75 ">Total Commission</div>
                          <div className="display-4 fw-bold">
                            {totalCommission ? (
                              totalCommission
                            ) : (
                              <small>0</small>
                            )}
                          </div>
                        </div>
                        <i className="feather-xl text-white-50 fa fa-user-plus"></i>
                      </div>
                    </div>
                    <div className="card-footer d-flex align-items-center justify-content-between small">
                      <Link
                        className="text-white stretched-link"
                        to="/dashboard/Transactions"
                        style={{ cursor: "pointer" }}
                        onClick={() => setActiveScreen("newRegistrations")}
                      >
                        View All
                      </Link>
                      <div className="text-white">
                        <i className="fas fa-angle-right"></i>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="col-lg-6 col-xl-4 mb-4">
                <div className="card bg-secondary text-white h-100">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="me-3">
                        <div className="text-white-75 ">Total Bet</div>
                        <div className="display-4 fw-bold">
                          {totalBet ? totalBet : <small>0</small>}
                        </div>
                      </div>
                      <i className="feather-xl text-white-50 fa fa-wallet"></i>
                    </div>
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-between small">
                    <Link
                      className="text-white stretched-link"
                      to="/dashboard/GameList"
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveScreen("toDayDeposit")}
                    >
                      View All
                    </Link>
                    <div className="text-white">
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4 mb-4">
                <div className="card bg-warning text-white h-100">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="me-3">
                        <div className="text-white-75 ">Total Tables</div>
                        <div className="display-4 fw-bold">
                          {totalTables ? totalTables : <small>0</small>}
                        </div>
                      </div>
                      <i className="feather-xl text-white-50 fa fa-wallet "></i>
                    </div>
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-between small">
                    <Link
                      className="text-white stretched-link"
                      to="/dashboard/TableList"
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveScreen("todayWithdrawl")}
                    >
                      View All
                    </Link>
                    <div className="text-white">
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>

              {permissions.includes(9) || userType === "1" ? (
                <>
                  <div className="col-lg-6 col-xl-4 mb-4">
                    <div className="card bg-danger text-white h-100">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="me-3">
                            <div className="text-white-75 ">Today Deposit</div>
                            <div className="display-4 fw-bold">
                              {todayDeposit ? todayDeposit : <small>0</small>}
                            </div>
                          </div>
                          <i className="feather-xl text-white-50 fa fa fa-wallet"></i>
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between small">
                        <Link
                          className="text-white stretched-link"
                          to="/dashboard/Transactions"
                          style={{ cursor: "pointer" }}
                          onClick={() => setActiveScreen("totalWithdraw")}
                        >
                          View All
                        </Link>
                        <div className="text-white">
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-xl-4 mb-4">
                    <div className="card bg-info text-white h-100">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="me-3">
                            <div className="text-white-75 ">Today Fee</div>
                            <div className="display-4 fw-bold">
                              {todayFee ? todayFee : <small>0</small>}
                            </div>
                          </div>
                          <i className="feather-xl text-white-50 fa fa fa-wallet"></i>
                        </div>
                      </div>
                      <div className="card-footer d-flex align-items-center justify-content-between small">
                        <Link
                          className="text-white stretched-link"
                          to="/dashboard/Transactions"
                          style={{ cursor: "pointer" }}
                          onClick={() => setActiveScreen("totalDeposit")}
                        >
                          View All
                        </Link>
                        <div className="text-white">
                          <i className="fas fa-angle-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <div className="col-lg-6 col-xl-4 mb-4">
                <div className="card bg-gradient-primary-to-secondary text-white h-100">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="me-3">
                        <div className="text-white-75 ">Today Withdrawal</div>
                        <div className="display-4 fw-bold">
                          {todayWithdrawal ? todayWithdrawal : <small>0</small>}
                        </div>
                      </div>
                      <i className="feather-xl text-white-50 fas fa-headset "></i>
                    </div>
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-between small">
                    <Link
                      className="text-white stretched-link"
                      to=""
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveScreen("supportpage")}
                    >
                      View All
                    </Link>
                    <div className="text-white">
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4 mb-4">
                <div className="card bg-secondary text-white h-100">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="me-3">
                        <div className="text-white-75 ">Total Players</div>
                        <div className="display-4 fw-bold">
                          {todayPlayers ? todayPlayers : <small>0</small>}
                        </div>
                      </div>
                      <i className="feather-xl text-white-50 fas fa-headset "></i>
                    </div>
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-between small">
                    <Link
                      className="text-white stretched-link"
                      to="/dashboard/Players"
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveScreen("supportpage")}
                    >
                      View All
                    </Link>
                    <div className="text-white">
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4 mb-4">
                <div className="card bg-gradient-primary-to-secondary text-white h-100">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="me-3">
                        <div className="text-white-75 "> Players Playing</div>
                        <div className="display-4 fw-bold">
                          {playersPlaying ? playersPlaying : <small>0</small>}
                        </div>
                      </div>
                      <i className="feather-xl text-white-50 fas fa-headset "></i>
                    </div>
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-between small">
                    <Link
                      className="text-white stretched-link"
                      to="/dashboard/Players"
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveScreen("supportpage")}
                    >
                      View All
                    </Link>
                    <div className="text-white">
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 col-xl-4 mb-4">
                <div className="card bg-danger text-white h-100">
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="me-3">
                        <div className="text-white-75 "> Total Balance</div>
                        <div className="display-4 fw-bold">
                          {totalBalance ? totalBalance : <small>0</small>}
                        </div>
                      </div>
                      <i className="feather-xl text-white-50 fas fa-headset "></i>
                    </div>
                  </div>
                  <div className="card-footer d-flex align-items-center justify-content-between small">
                    <Link
                      className="text-white stretched-link"
                      to=""
                      style={{ cursor: "pointer" }}
                      onClick={() => setActiveScreen("supportpage")}
                    >
                      View All
                    </Link>
                    <div className="text-white">
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
export default HomePage;
