import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const PlanList = () => {
  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [planName, setPlanName] = useState("");
  const [price, setPrice] = useState("");
  const [coin, setCoin] = useState("");
  const [description, setDescription] = useState("");

  const columns = [
    { name: "Sr No", selector: (row, index) => row?.index, width: "70px" },
    {
      name: "Date",
      selector: (row) => moment(row?.createdAt).format("Do MMMM YYYY"),
    },
    { name: "Plan Name", wrap: true, selector: (row) => row?.planName },
    { name: "Price", wrap: true, selector: (row) => row?.price },
    { name: "Coin", wrap: true, selector: (row) => row?.coin },
    { name: "Description", wrap: true, selector: (row) => row.description },
  ];

  useEffect(() => {
    handleShopPlans();
  }, []);

  const handleShopPlans = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getShopPlans().then(async (result) => {
      LoaderHelper.loaderStatus(false);
      if (result.data) {
        try {
          let fillteredData = result?.data
            ?.reverse()
            ?.map((item, index) => ({ ...item, index: index + 1 }));
          setData(fillteredData);
          setAllData(fillteredData);
        } catch (error) {
          alertErrorMessage("No data found");
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No data found");
      }
    });
  };
  const AddNewPlan = async (planName, price, coin, description) => {
    await AuthService.AddNewPlan(planName, price, coin, description).then(
      async (result) => {
        if (result.success) {
          try {
            handleShopPlans();
            alertSuccessMessage(result.message);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "planName":
        setPlanName(event.target.value);
        break;
      case "price":
        setPrice(event.target.value);
        break;
      case "coin":
        setCoin(event.target.value);
        break;
      case "description":
        setDescription(event.target.value);
        break;

      default:
    }
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      {" "}
                      <i className="fa fa-user-check"></i>
                    </div>
                    SHOP'S PLAN LIST
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between">
              Plan List
              <div className="dropdown">
                <button
                  className="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#add_modal"
                >
                  <i className="fas fa-plus me-2"></i> Add New
                </button>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase
                  columns={columns}
                  data={data}
                  pagination
                  highlightOnHover
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      {/* add_modal */}
      <div
        className="modal fade"
        id="add_modal"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="add_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="add_modalLabel">
                Add New Plan{" "}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="row g-4 gx-md-5">
                <div className="  col-md-6">
                  <label for="" className=" ">
                    Plan Name <span className="text-danger">* </span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    name="planName"
                    placeholder="Enter Plan Name"
                    value={planName}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="" className=" ">
                    Price <span className="text-danger">* </span>{" "}
                  </label>
                  <input
                    type="number"
                    className="form-control form-control-solid"
                    name="price"
                    placeholder="Enter Price"
                    value={price}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="" className=" ">
                    Coin <span className="text-danger">* </span>{" "}
                  </label>
                  <input
                    type="number"
                    className="form-control form-control-solid"
                    name="coin"
                    placeholder="Enter Coin"
                    value={coin}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label for="" className=" ">
                    Description <span className="text-danger">* </span>{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-solid"
                    name="description"
                    placeholder="Enter Description"
                    value={description}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-dark  px-5"
                data-bs-dismiss="modal"
              >
                {" "}
                Cancel{" "}
              </button>
              <button
                type="button"
                className="btn btn-primary px-5"
                data-bs-dismiss="modal"
                onClick={() => AddNewPlan(planName, price, coin, description)}
              >
                Save
              </button>{" "}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanList;
