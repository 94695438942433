import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const Leaderboard = () => {
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);

  const columns = [
    { name: "S No", selector: (row, index) => row?.index, width: "100px" },
    {
      name: "User ID",
      selector: (row) => row.userId,
      wrap: true,
      width: "300px",
    },
    {
      name: "Name",
      sort: true,
      wrap: true,
      selector: (row) => row?.username,
    },
    {
      name: "Rank",
      sort: true,
      wrap: true,
      selector: (row) => row.rank,
    },
    { name: "Wins", sort: true, selector: (row) => row.wins },
  ];

  function searchObjects(e) {
    const keysToSearch = ["userId", "username", "rank", "wins"];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter((obj) => {
      return keysToSearch.some((key) =>
        obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)
      );
    });
    setExportData(matchingObjects);
  }

  useEffect(() => {
    handleLeaderBoardData();
  }, []);

  const handleLeaderBoardData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.leaderboard().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          let fillteredData = result?.data
            ?.reverse()
            ?.map((item, index) => ({ ...item, index: index + 1 }));
          setExportData(fillteredData);
          setallData(fillteredData);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");
      }
    });
  };

  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Leader Boarad List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-6">
            <div className="card-header d-flex justify-content-between">
              <div className="filter_bar">
                <form className="row">
                  <div className="col">
                    <input
                      className="form-control form-control-solid h-auto"
                      id="inputLastName"
                      type="text"
                      placeholder="User id...."
                      name="search"
                      onChange={searchObjects}
                    />
                  </div>
                  {/* <div className="col">
                                        <input className="form-control form-control-solid h-auto" id="inputLastName" type="text" placeholder="Search...." name="search" onChange={searchObjects} />
                                    </div> */}
                  {/* <div className="col">
                                        <input type="date" className="form-control form-control-solid" data-provide="datepicker"
                                            id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                                    </div>
                                    <div className="col">
                                        <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={toDate}
                                            onChange={(e) => { setToDate(e.target.value); }} />
                                    </div>
                                    <div className="col">
                                        <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                                            Search
                                        </button>
                                    </div> */}
                </form>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={exportData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Leaderboard;
