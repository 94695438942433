import React, { useEffect, useState } from "react";
import AuthService from "../../../api/services/AuthService";
import { alertErrorMessage, alertSuccessMessage, } from "../../../customComponent/CustomAlertMessage";
import TraderDetails from "../Monthly";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const GstInvoice = () => {
  const [activeScreen, setActiveScreen] = useState("userdetail");
  const [userId, setUserId] = useState("");
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);
  const [traderData, settraderData] = useState();


  const columns = [
    { name: "User ID", selector: row => row.userId, wrap: true, grow: .5, },
    { name: "Name", sort: true, wrap: true, selector: row => row?.name, },
    { name: "Invoice No", sort: true, wrap: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY"), grow: .5, },
    { name: "Invoice Date", sort: true, wrap: true, selector: row => row.invoiceDate, grow: .5, },
    { name: "Amount", sort: true, wrap: true, selector: row => row.amount, },
    { name: "GST Amount", sort: true, wrap: true, selector: row => row.gstAmount, },
    { name: "State GST", sort: true, wrap: true, selector: row => row.stateGstAmount, },
    { name: "Phone Number", sort: true, selector: row => row.mobileNumber, wrap: true, },
    { name: "Registration Date", sort: true, wrap: true, selector: row => moment(row?.createdAt).format("Do MMMM YYYY") },

  ];

  useEffect(() => {
    handleGstInvoiceList();
  }, []);


  const handleGstInvoiceList = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getGstInvoiceList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          setExportData(result?.invoices.reverse());
          setallData(result?.invoices);
        } catch (error) {
          // alertErrorMessage(error);

        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");

      }
    });
  };


  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const filterDate = () => {
    const from = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : null;
    const to = toDate ? new Date(toDate).setHours(23, 59, 59, 999) : null;
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt).getTime();
      return (
        (from === null || createdAtDate >= from) &&
        (to === null || createdAtDate <= to)
      );
    });
    setExportData(filteredData.reverse());
  };

  return activeScreen === "userdetail" ? (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-wave-square"></i>
                    </div>
                    Invoice List
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">

          <div className="card mb-6 ">
            <div className="card-header d-flex justify-content-between p-2 ps-4 ">
              <p className="mb-0" ><span style={{ color: "red" }}> TOTAL RECORD </span> <b className="text-green ms-1 "> {allData?.length}</b></p>
            </div>
            <div className="card-header d-flex justify-content-end">
              <div className="filter_bar">
                <form className="row">

                  <div className=" col-4 col ">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker"
                      id="litepickerRangePlugin" name="dateFrom" value={fromDate} onChange={(e) => { setFromDate(e.target.value); }} />
                  </div>
                  <div className=" col-4 col ">
                    <input type="date" className="form-control form-control-solid" data-provide="datepicker" id="litepickerRangePlugin" name="dateTo" value={toDate}
                      onChange={(e) => { setToDate(e.target.value); }} />
                  </div>
                  <div className=" col-4 col ">
                    <button className="btn btn-indigo btn-block w-100" type="button" onClick={filterDate}>
                      Search
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-body">
              <div className="table-responsive" width="100%">
                <DataTableBase columns={columns} data={exportData} />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  ) : (
    <TraderDetails userId={userId} traderData={traderData} />
  );
};

export default GstInvoice;
