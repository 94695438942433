import React from "react";

const ReferralList = () => {



  return (
    <div id="layoutSidenav_content">
      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-xl px-4">
            <div className="page-header-content pt-4">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto mt-4">
                  <h1 className="page-header-title">
                    <div className="page-header-icon">
                      <i className="fa fa-check-circle"></i>
                    </div>
                    Referral User's Details
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </header>
        <div className="container-xl px-4 mt-n10">
          <div className="card mb-4">
            <div className="card-header d-flex justify-content-between pt-1 pb-1 ps-4">
              Referral Details
              <div className="col-5">
                <input className="form-control form-control-solid" id="inputLastName" type="text" placeholder="Search here..." name="search" />
              </div>
              <div className="dropdown">
                <button className="btn btn-dark dropdown-toggle" id="dropdownFadeInUp" pe="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  <i className="fa fa-download me-3"></i> Export
                </button>
                <div className="dropdown-menu animated--fade-in-up" aria-labelledby="dropdownFadeInUp">
                  <button className="dropdown-item"> Export to Excel</button>
                </div>
              </div>
            </div>
            <span className="text-center pt-1 pb-1"> No Data Available </span>
            <div className="card-body">
              <div className="table-responsive" width="100%"></div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ReferralList;
