const appUrl = "https://api.gitty.online";

export const ApiConfig = {
  // =========EndPoints==========
  login: "login",
  changepass: "changePassword",
  getdata: "kycPending",
  cpbalancebycoin: "cpbalancebycoin",
  getShopPlans: "getShopPlans",
  AddNewPlan: "addShopPlans",
  getTables: "getTables",
  getGamesData: "game",
  pendingWithraStatus: "approveWithdrawal",
  addNewTable: "addTable",
  updateBalance: "updateBalance",
  PankycApproved: "kycApproved",
  getkycData: "userKyc",
  handleInputChange: "settings",
  paymentSetting: "getPaymentSettings",
  updateGameSetting: "updateSettings",
  updatePaymentSetting: "updatePaySettings",
  PankycRejected: "kycRejected",
  verifyIdentity: "kycApproval",
  BankApproved: "bankApproved",
  BankRejected: "bankRejected",
  BankPending: "bankPending",
  getPending: "pending-kyc-count",
  leaderboard: "leaderboard",
  getSupport: "support",
  getPendingwithdrawal: "getPendingTransaction",
  getRejectedTransaction: "getRejectedTransaction",
  getApprovedTransaction: "getApprovedTransaction",
  updateBankStatus: "approvebank",
  updateTableStatus: "setTable",
  getGstInvoiceList: "gst/Invoices",
  getDashboardData: "dashboard",
  currencyPair: "allsinglecoinlist",
  getSubAdminList: "sub-admin-list",
  getPartnersList: "getpartnerships",
  getCoinListDetails: "getcoinListedDetails ",
  AddsubAdmin: "add-new-admin",
  AddTrade: "addnewtrader",
  subadmindelete: "delete_subadmin",
  getallorder: "getOrders",
  addNotify: "add_notification",
  deleteNotify: "delete-notification",
  notificationList: "notification-list",
  helplist: "getallhelplist",
  todayNewRegistrations: "today_new_registration",
  createCategory: "create-coin-category",
  SubadminStatus: "admin_status",
  PartnersStatus: "setStatusPartnership",
  CoinDetailsStatus: "setcoindetailstatus ",
  updateSubadmin: "edit_subadmin",
  tradeStatus: "kycsetstatus",
  getInrWithrawList: "withdraw_request",
  userreqapprove: "confirmInrWithdraw",
  userreqreject: "rejectInrWithdraw",
  addAdTicket: "addAdTicket",
  ticketList: "ticketlist",
  adminsupport: "adminsupport",
  sendmailtouser: "sendmailtouser",
  documentchange: "documentchange",
  transferhistory: "user-trade-history",
  userWallet: "user-wallet",
  admintransfer: "admintransfer",
  depositrequest: "depositrequest",
  withdrawlrequest: "withdrawlrequest",
  totaldepositrequest: "depositrequest",
  totalwithdrawlrequest: "getWithdrawlReq",
  getmasterbal: "getmasterbal",
  changeseen: "changeseen",
  currencyCoinList: "get-coin",
  walletCoinList: "adminwallet",
  setcoinwithdrawal: "edit-currency",
  getcpaccountinfo: "getcpaccountinfo",
  getcpcoinbalance: "getcpcoinbalance",
  AddCoinPair: "add-pairs",
  AddPairFee: "updatecoinfee",
  getCurrencyPairList: "get-pairs",
  tredingReport: "trading-commission",
  sendFundStatus: "transactionstatus",
  completeWithdrawalRequest: "complete_withdrawal_request",
  PendingWithdrwal: "pending_withdrawal_request",
  CancelledWithdrwal: "cancel_withdrawal_request",
  completeDepositRequest: "complete_deposit_request",
  completePendingRequest: "pending_deposit_request",
  inrdepositreq: "deposit_request",
  confirmInrDeposit: "update_deposit_status",
  rejectInrDeposit: "update_deposit_status",
  getUserWalletList: "select_given_coin",
  userWalletTransfer: "withdrawalcoin",
  setrewardrate: "kyc/setrewardrate",
  updatetdsrate: "updatetdsrate",
  updatemailTamplate: "updatemailTamplate",
  playerList: "getAllUsers",
  pendingtrader: "pendingtrader",
  fiatwithreq: "fiatwithreq",
  verifiedtrader: "verifiedtrader",
  fiatdepreq: "fiatdepreq",
  addNewCoins: "add-coin",
  getMasterWalletList: "adminwallettransactions",
  admincoinaddress: "admincoinaddress",
  getreceive: "getreceive",
  stakingList: "stacking/getAllUserstacking",
  transactionstatus: "update_withdrawal_status",
  Addbanner: "uploadBanner",
  bannerList: "bannerList",
  updateBanner: "update_banner",
  bannerdelete: "delete_banner/",
  BannerStatus: "updateBanner",
  deleteCoinPair: "delete-pair",
  walletStatus: "withdrawstatus",
  getOtp: "auth/getcode",
  walletTransfer: "/transfertoother",
  addBankAcc: "add_bank_details",
  getAccDetails: "get_user_bank_details",
  fundsTransfer: "debit_credit",
  getCoinList: "get-coin",
  CoinCategory: "coin-category-list",
  tradingCommission: "trading-commission",
  tradeHistory: 'trade-history',
  OrderBook: 'orderbook',
  getverifyidentity: 'kycApproval',
  blockUser: 'blockUser',
  tradeById: 'find-trades-by-orderid',
  miscellaneousRequest: 'miscellaneous_withdrawal_request',
  MasterAccount: 'master-account',
  botStatus: 'bot-status',
  cancelOrder: 'cancel-order',
  verifyBankDetails: "update-bank-status",
  pendingBankDetails: "pending-bank_details",
  approveBankDetails: "approve-bank_details",
  rejectBankDetails: "rejected-bank_details",
  allTransactions: 'allTransactions',
  getUserDetails: "getUserProfile",


  // ============URLs================
  baseHelp: `${appUrl}/help/`,
  baseTran: `${appUrl}/v1/transaction/`,
  baseUrl: `${appUrl}/admin/`,
  uploadUrl: `${appUrl}/uploads/`,
  basenotify: `${appUrl}/acc/`,
  baseSecure: `${appUrl}/v1/coin/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseReport: `${appUrl}/report/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseAdmin: `${appUrl}/v1/admin/`,
  baseUser: `${appUrl}/v1/user/`,
  uploadcurrency: `${appUrl}/`,
  appUrl: `${appUrl}/`,
  baseUsers: `${appUrl}/user/`,

};
