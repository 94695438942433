import React, { useState } from "react";
import { Link, Outlet } from "react-router-dom";
import Header from "../../../customComponent/Header";
import { useEffect } from "react";

const DashboardPage = () => {
    const [activeTab, setActiveTab] = useState('');
    const myPermission = sessionStorage.getItem('permissions');
    const userType = sessionStorage.getItem('userType');
    let permissions = Array.isArray(JSON.parse(myPermission)) ? JSON.parse(myPermission)?.map(x => x.value) : [];

    useEffect(() => {
        let URL = window.location.href?.split('/');
        let route = URL.pop();
        setActiveTab(route)
    }, []);


    return (
        <>
            <Header />
            <div id="layoutSidenav" >
                <div id="layoutSidenav_nav">
                    <nav className="sidenav shadow-right sidenav-light">
                        <div className="sidenav-menu">
                            <div className="nav accordion" id="accordionSidenav">
                                <div className="sidenav-menu-heading">pages</div>
                                <Link to="/dashboard/homepage" className={`nav-link collapsed ${activeTab?.includes('homepage') ? 'active' : ''}`} onClick={() => setActiveTab('homepage')}>
                                    <div className="nav-link-icon"><i className="fa fa-th"></i></div>
                                    Dashboards
                                </Link>

                                {permissions.includes(1) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('Players') ? 'active' : ''}`} to="Players" onClick={() => setActiveTab('Players')}>
                                        <div className="nav-link-icon"><i className="fa fa-users"></i></div>
                                        Players
                                    </Link>
                                    : null
                                }

                                {permissions.includes(2) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('PlanList') ? 'active' : ''}`} to="PlanList" onClick={() => setActiveTab('PlanList')}>
                                        <div className="nav-link-icon"><i className="far fa-lightbulb"></i></div>
                                        Plan List
                                    </Link>
                                    : null
                                }


                                {permissions.includes(3) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('TableList') ? 'active' : ''}`} to="TableList" onClick={() => setActiveTab('TableList')}>
                                        <div className="nav-link-icon"><i className="fas fa-table"></i></div>
                                        Table List
                                    </Link>
                                    : null
                                }

                                {permissions.includes(4) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('GameList') ? 'active' : ''}`} to="GameList" onClick={() => setActiveTab('GameList')}>
                                        <div className="nav-link-icon"><i className="fa fa-trophy"></i></div>
                                        Game List
                                    </Link>
                                    : null
                                }

                                {permissions.includes(3) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('Transactions') ? 'active' : ''}`} to='Transactions' onClick={() => setActiveTab('Transactions')}>
                                        <div className="nav-link-icon"><i className="fas fa-money-check-alt"></i></div>
                                        Transactions
                                    </Link>
                                    : null
                                }

                                {/* {permissions.includes(6) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('Withdrawal') || activeTab?.includes('Withdrawal') || activeTab?.includes('Withdrawal')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseWithdrawal" aria-expanded="false" aria-controls="collapseWithdrawal">
                                            <div className="nav-link-icon"><i className="fas fa-wallet"></i></div>
                                            Withdrawal
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseWithdrawal" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('WithdrawalPendingRequest') ? 'active' : ''}`} to="WithdrawalPendingRequest" onClick={() => setActiveTab('WithdrawalPendingRequest')}>Pending/Request</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('ApprovedRequest') ? 'active' : ''}`} to="ApprovedRequest" onClick={() => setActiveTab('ApprovedRequest')}>Approved</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('RejectRequest') ? 'active' : ''}`} to="RejectRequest" onClick={() => setActiveTab('RejectRequest')}>Reject/Declined</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('ReversedList') ? 'active' : ''}`} to="ReversedList" onClick={() => setActiveTab('ReversedList')}>Reversed List</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                } */}

                                {permissions.includes(8) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('kycRequest') || activeTab?.includes('kycRequest') || activeTab?.includes('kycApproved')) || activeTab?.includes('kycRejected') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseKYC" aria-expanded="false" aria-controls="collapseKYC">
                                            <div className="nav-link-icon"><i className="fas fa-university"></i></div>
                                            KYC
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseKYC" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('kycRequest') ? 'active' : ''}`} to="kycRequest" onClick={() => setActiveTab('kycRequest')}>Kyc Request</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('kycApproved') ? 'active' : ''}`} to="kycApproved" onClick={() => setActiveTab('kycApproved')}>Kyc Approved</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('kycRejected') ? 'active' : ''}`} to="kycRejected" onClick={() => setActiveTab('kycRejected')}>Kyc Rejected</Link>

                                                {/* <Link className={`nav-link  ${activeTab?.includes('BankUpiRequest') ? 'active' : ''}`} to="BankUpiRequest" onClick={() => setActiveTab('BankUpiRequest')}>Bank UPI/Request</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('BankUpiApproved') ? 'active' : ''}`} to="BankUpiApproved" onClick={() => setActiveTab('BankUpiApproved')}>Bank UPI/Approved</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('BankUpiRejected') ? 'active' : ''}`} to="BankUpiRejected" onClick={() => setActiveTab('BankUpiRejected')}>Bank UPI/Rejected</Link> */}
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(3) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('LeaderBoard') ? 'active' : ''}`} to='LeaderBoard' onClick={() => setActiveTab('LeaderBoard')}>
                                        <div className="nav-link-icon"><i className="fas fa-money-check-alt"></i></div>
                                        Leader Board
                                    </Link>
                                    : null
                                }

                                {permissions.includes(4) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('ReferralList') ? 'active' : ''}`} to="ReferralList" onClick={() => setActiveTab('ReferralList')}>
                                        <div className="nav-link-icon"><i className="fas fa-user-plus"></i></div>
                                        Referral List
                                    </Link>
                                    : null
                                }
                                {permissions.includes(3) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('GstInvoice') ? 'active' : ''}`} to='GstInvoice' onClick={() => setActiveTab('GstInvoice')}>
                                        <div className="nav-link-icon"><i className="fas fa-money-check-alt"></i></div>
                                        GST Invoice
                                    </Link>
                                    : null
                                }

                                {/* {permissions.includes(5) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('Leaderboard') || activeTab?.includes('collapseLeaderboard') || activeTab?.includes('Daily')) || activeTab?.includes('Monthly') || activeTab?.includes('AllTime') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseLeaderBoard" aria-expanded="false" aria-controls="collapseLeaderBoard">
                                            <div className="nav-link-icon"><i className="fas fa-cash-register"></i></div>
                                            Leader Board
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseLeaderBoard" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('Banner') ? 'active' : ''}`} to="Banner" onClick={() => setActiveTab('Banner')}>Banner</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('Refferal') ? 'active' : ''}`} to="Refferal" onClick={() => setActiveTab('Refferal')}>Refferal</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('Daily') ? 'active' : ''}`} to="Daily" onClick={() => setActiveTab('Daily')}>Daily</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('Monthly') ? 'active' : ''}`} to="Monthly" onClick={() => setActiveTab('Monthly')}>Monthly</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('AllTime') ? 'active' : ''}`} to="AllTime" onClick={() => setActiveTab('AllTime')}>All Time</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                } */}

                                {/* {permissions.includes(7) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('Reports') || activeTab?.includes('collapseDepositWithdrawal') || activeTab?.includes('Commission')) || activeTab?.includes('AdminTransation') || activeTab?.includes('GamePalyed') || activeTab?.includes('OnlinePlayer') || activeTab?.includes('TDSReports') || activeTab?.includes('GameCommission') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseReports" aria-expanded="false" aria-controls="collapseReports">
                                            <div className="nav-link-icon"><i className="fas fa-poll"></i></div>
                                            Reports
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseReports" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('DepositWithdrawalList') ? 'active' : ''}`} to="DepositWithdrawalList" onClick={() => setActiveTab('DepositWithdrawalList')}>Total Deposit Withdrawal</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('Commission') ? 'active' : ''}`} to="Commission" onClick={() => setActiveTab('Commission')}>Commission</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('AdminTransation') ? 'active' : ''}`} to="AdminTransation" onClick={() => setActiveTab('AdminTransation')}>Admin Transation</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('GamePalyed') ? 'active' : ''}`} to="GamePalyed" onClick={() => setActiveTab('GamePalyed')}>Game Palyed User's</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('OnlinePlayer') ? 'active' : ''}`} to="OnlinePlayer" onClick={() => setActiveTab('OnlinePlayer')}>Online Player/Table</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('TDSReports') ? 'active' : ''}`} to="TDSReports" onClick={() => setActiveTab('TDSReports')}>TDS Reports</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('GameCommission') ? 'active' : ''}`} to="GameCommission" onClick={() => setActiveTab('GameCommission')}>Game Mode Commission</Link>
                                            </nav>
                                        </div>
                                    </>
                                    : null
                                } */}

                                {/* {permissions.includes(9) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('Tournament') || activeTab?.includes('LiveList') || activeTab?.includes('LiveList')) || activeTab?.includes('CompleteList') || activeTab?.includes('NewCreate') || activeTab?.includes('MasterList') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseTournament" aria-expanded="false" aria-controls="collapseTournament">
                                            <div className="nav-link-icon"><i className="fas fa-trophy"></i></div>
                                            Tournament
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseTournament" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('LiveList') ? 'active' : ''}`} to="LiveList" onClick={() => setActiveTab('LiveList')}>Live List</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('CompleteList') ? 'active' : ''}`} to="CompleteList" onClick={() => setActiveTab('CompleteList')}>Completed List</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('NewCreate') ? 'active' : ''}`} to="NewCreate" onClick={() => setActiveTab
                                                    ('NewCreate')}>New Create</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('MasterList') ? 'active' : ''}`} to="MasterList" onClick={() => setActiveTab('MasterList')}>Master List</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                } */}

                                {/* {permissions.includes(10) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('GSTInvoice') || activeTab?.includes('DepositInvoiceList')) || activeTab?.includes('DepositStateGst') || activeTab?.includes('GSTReport') || activeTab?.includes('StateGst') || activeTab?.includes('InvoiceList') || activeTab?.includes('WithdrawGstReports') || activeTab?.includes('WithdrawStateGST') || activeTab?.includes('WithdrawInvoiceList') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseGSTInvoice" aria-expanded="false" aria-controls="collapseGSTInvoice">
                                            <div className="nav-link-icon"><i className="fas fa-receipt"></i></div>
                                            GST Invoice
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseGSTInvoice" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('DepositInvoiceList') ? 'active' : ''}`} to="DepositInvoiceList" onClick={() => setActiveTab('DepositInvoiceList')}>Deposit Invoice List</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('DepositStateGst') ? 'active' : ''}`} to="DepositStateGst"
                                                    onClick={() => setActiveTab('DepositStateGst')}>Deposit State GST</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('GSTReport') ? 'active' : ''}`} to="GSTReport"
                                                    onClick={() => setActiveTab('GSTReport')}>GST Report</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('StateGst') ? 'active' : ''}`} to="StateGst"
                                                    onClick={() => setActiveTab('StateGst')}>State GST Total</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('InvoiceList') ? 'active' : ''}`} to="InvoiceList"
                                                    onClick={() => setActiveTab('InvoiceList')}>Invoice List</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('WithdrawGstReports') ? 'active' : ''}`} to="WithdrawGstReports" onClick={() => setActiveTab('WithdrawGstReports')}>Withdraw GST Reports</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('WithdrawStateGST') ? 'active' : ''}`} to="WithdrawStateGST"
                                                    onClick={() => setActiveTab('WithdrawStateGST')}>Withdraw State GST Total</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('WithdrawInvoiceList') ? 'active' : ''}`} to="WithdrawInvoiceList" onClick={() => setActiveTab('WithdrawInvoiceList')}>Withdraw Invoice List</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                } */}

                                {permissions.includes(11) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('Notification') || activeTab?.includes('Sms') || activeTab?.includes('Fcm')) ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseNotification" aria-expanded="false" aria-controls="collapseNotification">
                                            <div className="nav-link-icon"><i className="fas fa-bell"></i></div>
                                            Notification
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseNotification" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('Sms') ? 'active' : ''}`} to="sms" onClick={() => setActiveTab('Sms')}>SMS</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('Fcm') ? 'active' : ''}`} to="fcm" onClick={() => setActiveTab('Fcm')}>FCM</Link>

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(12) || userType === '1' ?
                                    <>
                                        <div className={`nav-link collapsed ${(activeTab?.includes('GameSetting') || activeTab?.includes('collapseSetting') || activeTab?.includes('PaymentGateway')) || activeTab?.includes('PayoutGateway') || activeTab?.includes('PayoutAuto') || activeTab?.includes('Banner') || activeTab?.includes('AppContact') || activeTab?.includes('PaymentSetting') || activeTab?.includes('VersionList') || activeTab?.includes('Notice') ? 'active' : ''}`} data-bs-toggle="collapse" data-bs-target="#collapseSetting" aria-expanded="false" aria-controls="collapseGSTInvoice">
                                            <div className="nav-link-icon"><i className="fas fa-wrench"></i></div>
                                            Setting
                                            <div className="sidenav-collapse-arrow"><i className="fas fa-angle-down"></i></div>
                                        </div>
                                        <div className="collapse" id="collapseSetting" data-bs-parent="#accordionSidenav">
                                            <nav className="sidenav-menu-nested nav">
                                                <Link className={`nav-link  ${activeTab?.includes('GameSetting') ? 'active' : ''}`} to="GameSetting" onClick={() => setActiveTab('GameSetting')}>Game Setting</Link>
                                                <Link className={`nav-link  ${activeTab?.includes('PaymentSetting') ? 'active' : ''}`} to="PaymentSetting" onClick={() => setActiveTab('PaymentSetting')}>Payment Setting</Link>

                                                {/* <Link className={`nav-link  ${activeTab?.includes('PaymentGateway') ? 'active' : ''}`} to="PaymentGateway" onClick={() => setActiveTab('PaymentGateway')}>Payment Gateway</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('PayoutGateway') ? 'active' : ''}`} to="" onClick={() => setActiveTab('PayoutGateway')}>Payout Gateway</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('PayoutAuto') ? 'active' : ''}`} to="#" onClick={() => setActiveTab('PayoutAuto')}>Payout Auto</Link> */}

                                                <Link className={`nav-link  ${activeTab?.includes('Banner') ? 'active' : ''}`} to="Banner" onClick={() => setActiveTab('Banner')}>Banner</Link>

                                                {/* <Link className={`nav-link  ${activeTab?.includes('AppContact') ? 'active' : ''}`} to="AppContact" onClick={() => setActiveTab('AppContact')}>App Contact</Link> */}

                                                {/* <Link className={`nav-link  ${activeTab?.includes('Maintenance') ? 'active' : ''}`} to="Maintenance" onClick={() => setActiveTab('Maintenance')}>Maintenance</Link> */}

                                                {/* <Link className={`nav-link  ${activeTab?.includes('VersionList') ? 'active' : ''}`} to="VersionList" onClick={() => setActiveTab('VersionList')}>Version List</Link>

                                                <Link className={`nav-link  ${activeTab?.includes('Notice') ? 'active' : ''}`} to="Notice" onClick={() => setActiveTab('Notice')}>Notice</Link> */}

                                            </nav>
                                        </div>
                                    </>
                                    : null
                                }

                                {permissions.includes(13) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('ChangePassword') ? 'active' : ''}`} to='ChangePassword' onClick={() => setActiveTab('ChangePassword')}>
                                        <div className="nav-link-icon"><i className="fas fa-unlock-alt"></i></div>
                                        Change Password
                                    </Link>
                                    : null
                                }

                                {/* {permissions.includes(3) || userType === '1' ?
                                    <Link className={`nav-link collapsed ${activeTab?.includes('Logout') ? 'active' : ''}`} to='Logout' onClick={() => setActiveTab('Logout')}>
                                        <div className="nav-link-icon"><i className="fa fa-compress"></i></div>
                                        Logout
                                    </Link>
                                    : null
                                } */}

                            </div>
                        </div>
                    </nav >
                </div >
                <Outlet />
            </div>
        </>
    )
}

export default DashboardPage;