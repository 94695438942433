import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../../api/services/AuthService";
import {
  alertErrorMessage,
  alertSuccessMessage,
} from "../../../customComponent/CustomAlertMessage";
import { CSVLink } from "react-csv";
import moment from "moment";
import LoaderHelper from "../../../customComponent/Loading/LoaderHelper";
import DataTableBase from "../../../customComponent/DataTable";

const Players = () => {
  const navigate = useNavigate();
  // const userId = sessionStorage.getItem("userId");
  const [exportData, setExportData] = useState([]);
  const [allData, setallData] = useState([]);
  const [status, setStatus] = useState("Active");
  const [totalBalance, setTotalBalance] = useState("");
  const [winningBalance, setWinningBalance] = useState("");
  const [depositBalance, setDepositBalance] = useState("");
  const [cashBonus, setCashBonus] = useState("");
  const [bonus, setBonus] = useState("");
  const [userId, setUserId] = useState("");

  const handleInputChange = (event) => {
    switch (event.target.name) {
      case "totalBalance":
        setTotalBalance(event.target.value);
        break;
      case "winningBalance":
        setWinningBalance(event.target.value);
        break;
      case "depositBalance":
        setDepositBalance(event.target.value);
        break;
      case "cashBonus":
        setCashBonus(event.target.value);
        break;
      case "bonus":
        setBonus(event.target.value);
        break;
      default:
    }
  };

  useEffect(() => {
    sessionStorage.setItem("status", status);
  }, [status]);

  const blockUser = async (id, status) => {
    await AuthService.blockUser(id, status).then(async (result) => {
      if (result.success) {
        try {
          handlePlayerData();
          alertSuccessMessage(result.message);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        alertErrorMessage(result.message);
      }
    });
  };

  const handleButtonClick = (row) => {
    return (
      <div style={{ textAlign: "center" }}>
        {row.depositBalance + row.winningBalance
          ? row.depositBalance + row.winningBalance
          : 0}
        <button
          className="btn btn-primary btn-sm"
          data-bs-toggle="modal"
          data-bs-target="#add_modal"
          onClick={() => setUserId(row?._id)}
        >
          Add/Remove
        </button>
      </div>
    );
  };

  const linkFollow = (row) => {
    return (
      <div>
        {row?.status === "Active" ? (
          <button
            className="btn btn-success btn-sm me-2"
            onClick={() => {
              blockUser(row?.id, "Inactive");
            }}
          >
            Block User
          </button>
        ) : (
          <button
            className="btn btn-danger btn-sm me-2"
            onClick={() => {
              blockUser(row?.id, "Active");
            }}
          >
            Unblock User
          </button>
        )}
      </div>
    );
  };

  const handleUserClick = (userId) => {
    navigate(`/dashboard/UserDetails`, { state: { userId } });
  };

  const columns = [
    {
      name: "S No",
      wrap: true,
      selector: (row, index) => row?.index,
      width: "70px",
    },
    // {
    //   name: "User ID",
    //   wrap: true,
    //   width: "220px",
    //   selector: (row) => (
    //     <button
    //       onClick={() => handleUserClick(row?._id)}
    //       className="btn p-0"
    //       style={{ color: "blue" }}
    //     >
    //       {row?._id}
    //     </button>
    //   ),
    // },
    {
      name: "User ID",
      sort: true,
      wrap: true,
      selector: (row) => row._id,
      sortable: true,
      width: "200px",
    },
    {
      name: "User Name",
      sort: true,
      wrap: true,
      selector: (row) => row.username,
      sortable: true,
      width: "200px",
    },
    {
      name: "Mobile Number",
      sort: true,
      wrap: true,
      selector: (row) => row.mobileNumber,
    },
    {
      name: "Deposit Wallet",
      sort: true,
      wrap: true,
      selector: (row) => row.depositBalance,
    },
    {
      name: "Win Wallet",
      sort: true,
      wrap: true,
      selector: (row) => row.winningBalance,
    },
    { name: "Balance", sort: true, wrap: true, selector: handleButtonClick },
    {
      name: "Registration Date",
      width: "200px",
      sort: true,
      wrap: true,
      selector: (row) => moment(row?.createdAt).format("Do MMMM YYYY"),
    },
    { name: "Status", sort: true, wrap: true, selector: (row) => row.status },
    { name: "Action", sort: true, wrap: true, selector: linkFollow },
  ];
  function searchObjects(e) {
    const keysToSearch = [
      "_id",
      "username",
      "mobileNumber",
      "depositBalance",
      "winningBalance",
      "status",
      "createdAt",
    ];
    const userInput = e.target.value;
    const searchTerm = userInput?.toLowerCase();
    const matchingObjects = allData.filter((obj) => {
      return keysToSearch.some((key) =>
        obj[key]?.toString()?.toLowerCase()?.includes(searchTerm)
      );
    });
    setExportData(matchingObjects);
  }

  useEffect(() => {
    handlePlayerData();
  }, []);

  const UpdateBalance = async (userId, depositBalance, cashBonus) => {
    await AuthService.updateBalance(userId, depositBalance, cashBonus).then(
      async (result) => {
        if (result.success) {
          try {
            handlePlayerData();
            setDepositBalance(result?.data?.depositBalance);
            setCashBonus(result?.data?.cashBonus);
            alertSuccessMessage(result.message);
          } catch (error) {
            alertErrorMessage(error);
          }
        } else {
          alertErrorMessage(result.message);
        }
      }
    );
  };

  const handlePlayerData = async () => {
    LoaderHelper.loaderStatus(true);
    await AuthService.getPlayerList().then(async (result) => {
      if (result.success) {
        LoaderHelper.loaderStatus(false);
        try {
          let fillteredData = result?.data
            ?.reverse()
            ?.map((item, index) => ({ ...item, index: index + 1 }));
          setExportData(fillteredData);
          setallData(fillteredData);
        } catch (error) {
          alertErrorMessage(error);
        }
      } else {
        LoaderHelper.loaderStatus(false);
        alertErrorMessage("No Data Available");
      }
    });
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const filterDate = () => {
    const from = fromDate ? new Date(fromDate).setHours(0, 0, 0, 0) : null;
    const to = toDate ? new Date(toDate).setHours(23, 59, 59, 999) : null;
    const filteredData = allData.filter((item) => {
      const createdAtDate = new Date(item.createdAt).getTime();
      return (
        (from === null || createdAtDate >= from) &&
        (to === null || createdAtDate <= to)
      );
    });
    setExportData(filteredData.reverse());
  };

  const toggleStatus = () => {
    const newStatus = status === "Active" ? "Inactive" : "Active";
    setStatus(newStatus);
    allData.forEach((player) => blockUser(player.id, newStatus));
  };
  return (
    <>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
            <div className="container-xl px-4">
              <div className="page-header-content pt-4">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto mt-4">
                    <h1 className="page-header-title">
                      <div className="page-header-icon">
                        <i className="fa fa-wave-square"></i>
                      </div>
                      Players List
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <div className="container-xl px-4 mt-n10">
            <div className="card mb-6 ">
              <div className="card-header justify-content-between p-0 ps-4 ">
                <p className="mb-0">
                  <span style={{ color: "red" }}> TOTAL RECORD </span>
                  <b className="text-green ms-1">{exportData?.length}</b>
                </p>
                <button
                  className={`btn ${status === "Active" ? "btn-danger" : "btn-success"
                    } btn-block w-70`}
                  type="button"
                  onClick={toggleStatus}
                >
                  {status === "Active"
                    ? "Block All Players"
                    : "Unblock All Players"}
                </button>
              </div>
              <div className="card-header d-flex justify-content-center align-items-center p-0 ps-4">
                <div className="filter_bar col-10">
                  <form className="d-flex align-items-center w-100">
                    <div className="col-4 pe-2">
                      <input
                        className="form-control form-control-solid h-auto"
                        id="inputLastName"
                        type="text"
                        placeholder="User Name/Mobile Number"
                        name="search"
                        onChange={searchObjects}
                      />
                    </div>
                    <div className="col-3 pe-2">
                      <input
                        type="date"
                        className="form-control form-control-solid"
                        id="litepickerRangePlugin"
                        name="dateFrom"
                        value={fromDate}
                        onChange={(e) => {
                          setFromDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-3 pe-2">
                      <input
                        type="date"
                        className="form-control form-control-solid"
                        id="litepickerRangePlugin"
                        name="dateTo"
                        value={toDate}
                        onChange={(e) => {
                          setToDate(e.target.value);
                        }}
                      />
                    </div>
                    <div className="col-2 pe-2">
                      <button
                        className="btn btn-indigo btn-block w-100"
                        type="button"
                        onClick={filterDate}
                      >
                        Search
                      </button>
                    </div>
                  </form>
                </div>
                <div className="dropdown">
                  <button
                    className="btn btn-dark dropdown-toggle"
                    id="dropdownFadeInUp"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="fa fa-download me-3"></i> Export
                  </button>
                  <div
                    className="dropdown-menu animated--fade-in-up"
                    aria-labelledby="dropdownFadeInUp"
                  >
                    <CSVLink data={exportData} className="dropdown-item">
                      Export as CSV
                    </CSVLink>
                  </div>
                </div>
              </div>

              <div className="card-body">
                <div className="table-responsive" width="100%">
                  <DataTableBase
                    columns={columns}
                    data={exportData}
                    pagination
                    paginationTotalRows={allData.length}
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
        {/* add_modal */}
        <div
          className="modal fade"
          id="add_modal"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="add_modalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="add_modalLabel">
                  Coin Add or Withdrawal
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <div className="row g-4 gx-md-5">
                  <div className="col-md-6">
                    <label for="" className=" ">
                      Deposit Balance <span className="text-danger">* </span>
                    </label>
                    <input
                      type="number"
                      className="form-control form-control-solid"
                      name="depositBalance"
                      value={depositBalance}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label for="" className=" ">
                      Cash Bonus <span className="text-danger">* </span>
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-solid"
                      name="cashBonus"
                      value={cashBonus}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-dark  px-5"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary px-5"
                  data-bs-dismiss="modal"
                  onClick={() =>
                    UpdateBalance(userId, depositBalance, cashBonus)
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Players;
