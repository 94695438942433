import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Loginpage from "../ui/pages/LoginPage";
import ForgetpasswordPage from "../ui/pages/ChangePassword";
import DashboardPage from "../ui/pages/DashboardPage";
import HomePage from "../ui/pages/HomePage";
import Players from "../ui/pages/Players";
import { ToastContainer } from "react-toastify";
import GameRecords from "../ui/pages/GameRecords";
import WithdrawalRequest from "../ui/pages/LiveList";
import CompleteRequest from "../ui/pages/CompleteRequest";
import Notification from "../ui/pages/Notification";
import BannerManagement from "../ui/pages/Banner";
import Notice from "../ui/pages/Notice";
import GSTReport from "../ui/pages/GSTReport";
import TDSReport from "../ui/pages/TDSReport";
import PlanList from "../ui/pages/PlanList";
import PaymentList from "../ui/pages/PaymentList";
import GameList from "../ui/pages/GameList";
import Banner from "../ui/pages/Banner";
import Daily from "../ui/pages/Daily";
import Monthly from "../ui/pages/Monthly";
import AllTime from "../ui/pages/AllTime";
import Commission from "../ui/pages/Commission";
import AdminTransation from "../ui/pages/AdminTransation";
import GamePlayerUsers from "../ui/pages/GamePlayerUsers";
import OnlinePlayer from "../ui/pages/OnlinePalyer/Table";
import TDSReports from "../ui/pages/TDSReport";
import GameCommission from "../ui/pages/GameCommission";
import BankUpiRequest from "../ui/pages/BankUpiRequest";
import BankUpiApproved from "../ui/pages/BankUpiApproved";
import BankUpiRejected from "../ui/pages/BankUpiRejected";
import LiveList from "../ui/pages/LiveList";
import CompleteList from "../ui/pages/CompleteList";
import NewCreate from "../ui/pages/NewCreate";
import MasterList from "../ui/pages/MasterList";
import DepositInvoiceList from "../ui/pages/DepositInvoiceList";
import DepositStateGst from "../ui/pages/DepositStateGst";
import StateGst from "../ui/pages/StateGst";
import WithdrawGstReports from "../ui/pages/WithdrawGstReports";
import WithdrawStateGST from "../ui/pages/WithdrawStateGST";
import WithdrawInvoiceList from "../ui/pages/WithdrawInvoiceList";
import GameSetting from "../ui/pages/GameSetting";
import AppContact from "../ui/pages/AppContact";
import Maintenance from "../ui/pages/Maintenance";
import ChangePassword from "../ui/pages/ChangePassword";
import PendigWithdrawal from "../ui/pages/Pendin/Request";
import ApprovedWithdrawal from "../ui/pages/ApprovedWithdrawal";
import RejectWthRequest from "../ui/pages/Rejected/Declined";
import ReversedList from "../ui/pages/ReversedList";
import DepositWithdrawalList from "../ui/pages/DepositWithdrawalList";
import TableList from "../ui/pages/TableList";
import Leaderboard from "../ui/pages/LeaderBoard/LeaderBoard";
import GstInvoice from "../ui/pages/GstInvoice";
import PaymentSetting from "../ui/pages/PaymentSetting";
import KycRequest from "../ui/pages/KycRequest/KycRequest";
import KycApproved from "../ui/pages/KycApproved/KycApproved";
import KycRejected from "../ui/pages/KycRejected/KycRejected";
import Transactions from "../ui/pages/Transactions";
import UserDetails from "../ui/pages/UserDetails/UserDetails";
import ReferralList from "../ui/pages/ReferralList";

// import 'bootstrap/dist/css/bootstrap.min.css';

const Routing = () => {
  const token = sessionStorage.getItem("token");

  return (
    <Router>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} auseOnFocusLoss={false} draggable pauseOnHover limit={1} theme="light" />
      <Routes>
        {token ? (
          <>
            <Route exact path="/dashboard" element={<DashboardPage />}>
              <Route index path="*" element={<HomePage />}></Route>
              <Route exect path="homepage" element={<HomePage />}></Route>
              <Route exect path="Players" element={<Players />}></Route>
              <Route exect path="PaymentList" element={<PaymentList />}></Route>
              <Route exect path="Transactions" element={<Transactions />}></Route>
              <Route exect path="GameRecords" element={<GameRecords />}></Route>
              <Route exect path="PlanList" element={<PlanList />}></Route>
              <Route exect path="TableList" element={<TableList />}></Route>
              <Route exect path="GameList" element={<GameList />}></Route>
              <Route exect path="WithdrawalRequest" element={<WithdrawalRequest />}></Route>
              <Route exect path="CompleteRequest" element={<CompleteRequest />}></Route>
              <Route exect path="notification" element={<Notification />}></Route>
              <Route exect path="Maintenance" element={<Maintenance />}></Route>
              <Route exect path="BannerManagement" element={<BannerManagement />}></Route>
              <Route exect path="Notice" element={<Notice />}></Route>
              <Route exect path="GSTReport" element={<GSTReport />}></Route>
              <Route exect path="TDSReport" element={<TDSReport />}></Route>
              <Route exect path="Banner" element={<Banner />}></Route>
              <Route exect path="Daily" element={<Daily />}></Route>
              <Route exect path="Monthly" element={<Monthly />}></Route>
              <Route exect path="AllTime" element={<AllTime />}></Route>
              <Route exect path="Commission" element={<Commission />}></Route>
              <Route exect path="AdminTransation" element={<AdminTransation />}></Route>
              <Route exect path="GamePalyed" element={<GamePlayerUsers />}></Route>
              <Route exect path="OnlinePlayer" element={<OnlinePlayer />}></Route>
              <Route exect path="TDSReports" element={<TDSReports />}></Route>
              <Route exect path="GameCommission" element={<GameCommission />}></Route>
              <Route exect path="kycRequest" element={<KycRequest />}></Route>
              <Route exect path="kycApproved" element={<KycApproved />}></Route>
              <Route exect path="kycRejected" element={<KycRejected />}></Route>
              <Route exect path="BankUpiRequest" element={<BankUpiRequest />}></Route>
              <Route exect path="BankUpiApproved" element={<BankUpiApproved />}></Route>
              <Route exect path="BankUpiRejected" element={<BankUpiRejected />}></Route>
              <Route exect path="LiveList" element={<LiveList />}></Route>
              <Route exect path="CompleteList" element={<CompleteList />}></Route>
              <Route exect path="NewCreate" element={<NewCreate />}></Route>
              <Route exect path="MasterList" element={<MasterList />}></Route>
              <Route exect path="DepositInvoiceList" element={<DepositInvoiceList />}></Route>
              <Route exect path="DepositStateGst" element={<DepositStateGst />}></Route>
              <Route exect path="StateGst" element={<StateGst />}></Route>
              <Route exect path="WithdrawGstReports" element={<WithdrawGstReports />}></Route>
              <Route exect path="WithdrawStateGST" element={<WithdrawStateGST />}></Route>
              <Route exect path="WithdrawInvoiceList" element={<WithdrawInvoiceList />}></Route>
              <Route exect path="GameSetting" element={<GameSetting />}></Route>
              <Route exect path="AppContact" element={<AppContact />}></Route>
              <Route exect path="Maintenance" element={<Maintenance />}></Route>
              <Route exect path="PaymentSetting" element={<PaymentSetting />}></Route>
              <Route exect path="ChangePassword" element={<ChangePassword />}></Route>
              <Route exect path="WithdrawalPendingRequest" element={<PendigWithdrawal />}></Route>
              <Route exect path="ApprovedRequest" element={<ApprovedWithdrawal />}></Route>
              <Route exect path="RejectRequest" element={<RejectWthRequest />}></Route>
              <Route exect path="ReversedList" element={<ReversedList />}></Route>
              <Route exect path="DepositWithdrawalList" element={<DepositWithdrawalList />}></Route>
              <Route exect path="Leaderboard" element={<Leaderboard />}></Route>
              <Route exect path="GstInvoice" element={<GstInvoice />}></Route>
              <Route exect path="Transactions" element={<Transactions />}></Route>
              <Route exect path="UserDetails" element={<UserDetails />}></Route>
              <Route exect path="ReferralList" element={<ReferralList />}></Route>
            </Route>
            <Route exect path="/*" element={<DashboardPage />}></Route>
          </>
        ) : (
          <>
            <Route exect path="/" element={<Loginpage />}></Route>
            <Route exect path="/*" element={<Loginpage />}></Route>
            <Route
              exect
              path="/forgotpassword"
              element={<ForgetpasswordPage />}
            ></Route>
            <Route
              exect
              path="/ReferralList"
              element={<ReferralList />}
            ></Route>
          </>
        )}
      </Routes>
    </Router>
  );
};

export default Routing;
